import React, {useRef, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Box, Grid} from '@mui/material';
import FormSubmitButtons from '../pieces/formSubmitButtons';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as allConstants from '../constants/allConstants';
import Utils from '../utils/utils';
import ModalWrapper, {ModalContentWrapper} from '../pieces/modals/modalWrapper';
import axios from 'axios';
import ApiUtils from '../utils/apiUtils';
import base_url from '../baseUrls';
//import * as STYLE_CONSTANTS from '../styles/styleConstants';
import Spacer from '../pieces/spacer';
import {inputTypes} from '../pieces/inputs/formInput';
import CustomFormBlock from '../pieces/inputs/formBlock';

export const useLookupItem = (props)=> {

  const [show, setShow] = useState(false);
  const [listData, setListData] = useState([]);
  const [defaultProperties, setDefaultProperties] = useState({});
  const [selectedText, setSelectedText] = useState('');
  const curRowData = useRef(null);
  const targetFieldNames = useRef(null);

  const headerText = 'Select ' + props?.title;

  const formInputDefaultProps = {
    viewMode: false,
    renderLabel: true,
  };

  const validationSchema = Yup.object().shape({
   // [allConstants.JOB_FIELDS.NOTE.TEXT.api_name]: Yup.string().required('Please leave a note')
  });

  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const mainForm = useForm(formOptions);

  if(Utils.isDevEnv()) {
    window.mainForm = mainForm;
  }

  // Thanh 02/02/24
  const onSubmit = async (data)=> {

    //console.log('Current row data =>');
    //console.log(curRowData.current);
    const selValue = data.selectedValue == 'UNASSIGNED' ? '' : data.selectedValue;

    const authToken =  await ApiUtils.getAccessToken();
    

    const body = { ...defaultProperties, [props.fieldName]: selValue };

    //console.log('body to sent to api');
    //console.log(body);

    const url = `${base_url.api}${props.apiUrl}`;
    
    axios.post(url, body, {headers: {Authorization: authToken,}}).then (() => {
      curRowData.current.node.setDataValue(props.fieldPath, selValue);
      //props.okAction(assignee);
    });
    
    setShow(false);
  };

  const onCancel = ()=> {
    setShow(false);
  };

 
const showDialog = (data, params, fieldNames, defProperties)=> {
    console.log('param inside showDialaog');
    console.log('default pro => ');
    console.log(defProperties);

    // console.log(params);
    // console.log(fieldNames);
    // console.log(data);

    let fields = fieldNames.split(',');
    let defValue = null;

    for (var i=0; i < fields.length; i++) {
      if (i==0) {
        defValue = params.node.data[fields[i]]??"";
        //console.log('def value');
        // console.log(defValue);
      }
      else {
        defValue = defValue[fields[i]]??"";
        //console.log('new default');
        //console.log(defValue);
        
      }
    }
    //submitAction.current = action;
    curRowData.current = params;
    targetFieldNames.current = fieldNames;

    const list = [...data, defValue];

    setListData(list);
    setDefaultProperties(defProperties);
    setSelectedText(defValue);
    setShow(true);
  };

  const mainDialog = () =><>
      <ModalWrapper modalName={'lookupItem'}
        handleClose={onCancel}
        open={show}
        preventScroll={true}
        width={{sx: '98%', sm: '600px'}}
        padding={6}
        customStyle={{paddingTop: 2}}
      >
        <ModalContentWrapper handleClose={onCancel} header={headerText} >
          <FormProvider {...mainForm}>
            <form onSubmit={mainForm.handleSubmit(onSubmit)} method={'POST'}>
              <CustomFormBlock
                  {...formInputDefaultProps}
                  field={allConstants.GENERAL_FIELDS.SELECTED_VALUE}
                  inputType={inputTypes.singleSelect}
                  defaultValue={selectedText}
                  options={listData}
                  fullWidth
                  isEditable={true}
                />
              <Grid>
                <Spacer y={2}/>
                <Box display={'flex'} justifyContent={'center'}>
                  <FormSubmitButtons cancelAction={onCancel}
                    submitText={'Select'} cancelText='Cancel'
                  />
                </Box>
              </Grid>
            </form>
          </FormProvider>
        </ModalContentWrapper>
      </ModalWrapper>
  </>;
  
  return {
    mainDialog: mainDialog,
    showDialog: showDialog,
    selectedText: selectedText,
  }
};