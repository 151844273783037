const USER_STATUSES = {
  INVITED: {display: 'invited', value: 'INVITED', showOrder: 0, def: false},
  ACTIVE: {display: 'active', value: 'ACTIVE', showOrder: 1, def: false},
  NOT_ACTIVE: {display: 'not active', value: 'INACTIVE', showOrder: -1, def: false},
  DISABLED: {display: 'disabled', value: 'DISABLED', showOrder: 2, def: false},
  BLOCKED: {display: 'blocked', value: 'Blocked', showOrder: -1, def: false},
  PENDING: {display: 'pending', value: 'PENDING', showOrder: -2, def: false},
  NOT_EXIST: {display: 'not exist', value: 'NOT EXIST', showOrder: -3, def: false},
};

const RATER_STATUSES = {
  ACTIVE: {display: 'active', value: 'ACTIVE', showOrder: 0, def: false},
  DISABLED: {display: 'disabled', value: 'DISABLED', showOrder: 1, def: false},
};

const COMPANY_STATUSES = {
  ACTIVE:  {display: 'active', value: 'Active', showOrder: 0, def: false},
  NOT_SIGNED:  {display: 'not signed', value: 'Not Signed', showOrder: 1, def: false},
  NOT_ACTIVE:  {display: 'not active', value: 'Deactivated', showOrder: 2, def: false},
  BLOCKED:  {display: 'blocked', value: 'Blocked', showOrder: 3, def: false},
  INACTIVE: {display: 'migrated', value: 'Inactive', showOrder: -1, def:false},
  INVITED:  {display: 'invited', value: 'Invited', showOrder: -2, def: false},
  PENDING: {display: 'pending', value: 'Pending', showOrder: -3, def: false},
};

const PERMIT_STATUSES = {
  NEW: {display: 'new', value: 'New', showOrder: 0, def: false},
  COMPLETED: {display: 'completed', value: 'Completed', showOrder: 1, def: false},
  CANCELLED: {display: 'cancelled', value: 'Cancelled', showOrder: 2, def: false},
  HOLD: {display: 'hold', value: 'Hold', showOrder: 3, def: false},
  PENDING: {display: 'pending', value: 'Pending', showOrder: 4, def: false},
  PULLED_NOT_UPLOADED: {display: 'pulled (not uploaded)', value: 'Pulled (not uploaded)', showOrder: 5, def: false},
  APPLIED_FOR_REFUND: {display: 'applied for refund', value: 'Applied For Refund', showOrder: 6, def: false},
  APPOINTMENT_SCHEDULED: {display: 'appointment scheduled', value: 'Appointment Scheduled', showOrder: 7, def: false},
  NOT_REQUIRED: {display: 'not required', value: 'Not Required', showOrder: 8, def: false},
  NEED_ATTENTION: {display: 'needs attention', value: 'Needs Attention', showOrder: 9, def: false},
  SUBMITTED: {display: 'submitted', value: 'Submitted', showOrder: 10, def: false},
};

const HERS_STATUSES = {
  NEED_ATTENTION: {display: 'needs attention', value: 'Needs Attention', showOrder: 0, def: false},
  COMPLETED: {display: 'completed', value: 'Completed', showOrder: 1, def: false},
  CANCELLED: {display: 'cancelled', value: 'Cancelled', showOrder: 2, def: false},
  SCHEDULED: {display: 'scheduled', value: 'Scheduled', showOrder: 3, def: false},
  READY_TO_RESCHEDULE: {display: 'ready to reschedule', value: 'Ready to Reschedule', showOrder: 4, def: false},
  HOLD_OFF: {display: 'hold off', value: 'Hold Off', showOrder: 5, def: false},
  PENDING: {display: 'pending', value: 'Pending', showOrder: 6, def: false},
  FAILED: {display: 'failed', value: 'Failed', showOrder: 7, def: false},
  RESCHEDULED: {display: 'rescheduled', value: 'Rescheduled', showOrder: 8, def: false},
  EMAILED_DIRECTLY: {display: 'emailed directly', value: 'Emailed Directly', showOrder: 9, def: false},
  NEW: {display: 'new', value: 'New', showOrder: 10, def: false},
  NOT_REQUIRED: {display: 'not required', value: null, showOrder: 11, def: false},
};
const MAILING_STATUSES = {
  NEW: {display: 'new', value: 'New', showOrder: 0, def: true},
  //NEED_ATTENTION: {display: 'needs attention', value: 'Needs Attention', showOrder: 0, def: false},
  READY_TO_MAIL: {display: 'ready to mail', value: 'Ready To Mail', showOrder: 1, def: false},
  MAILED: {display: 'mailed', value: 'Mailed', showOrder: 2, def: false},
  REMAILED: {display: 'remailed', value: 'Remailed', showOrder: 3, def: false},
  MAILED_TO_CONTRACTOR: {display: 'mailed to contractor', value: 'Mailed To Contractor', showOrder: 4, def: false},
  EMAILED: {display: 'emailed', value: 'Emailed', showOrder: 5, def: false},
  NO_NEED_TO_MAIL: {display: 'no need to mail', value: 'No Need To Mail', showOrder: 6, def: false},
  CANCEL: {display: 'cancel', value: 'Cancel', showOrder: 7, def: false},
};

const INSPECTION_STATUSES = {
  NEW: {display: 'New', value: 'New', showOrder: 0, def: true},
  PENDING: {display: 'Pending', value: 'Pending', showOrder: 1, def: false},
  SCHEDULED: {display: 'Final Scheduled', value: 'Scheduled', showOrder: 2, def: false},
  SCHEDULED_ROUGH: {display: 'Rough Scheduled', value: 'Scheduled', showOrder: 3, def: false},
  RESCHEDULED: {display: 'Re Scheduled', value: 'Rescheduled', showOrder: 4, def: false},
  MAILED_CERT_LETTER: {display: 'Mail Certified Letter', value: 'Mail Certified Letter', showOrder: 5, def: false},
  CANCELLED: {display: 'Cancelled', value: 'Cancelled', showOrder: 6, def: false},
  HOLD: {display: 'Hold', value: 'Hold', showOrder: 7, def: false},
  COMPLETED: {display: 'Completed', value: 'Completed', showOrder: 8, def: false}
};


const SERVICE_TITAN_STATUSES = {
  FAILED: {display: 'Failed', value: 'Failed', showOrder: 0, def: true},
  CREATED: {display: 'Created', value: 'Created', showOrder: 1, def: false},
  DELETED: {display: 'Deleted', value: 'Deleted', showOrder: 2, def: true},
  IGNORED: {display: 'Ignored', value: 'Ignored', showOrder: 3, def: false},
  DOCUMENTUPLOADED: {display: 'Document Uploaded', value: 'Document Uploaded', showOrder: 4, def: false},

}

export {USER_STATUSES,
  COMPANY_STATUSES,
  PERMIT_STATUSES,
  HERS_STATUSES,
  MAILING_STATUSES,
  INSPECTION_STATUSES,
  RATER_STATUSES,
  SERVICE_TITAN_STATUSES
};
