import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import * as allConstants from '../../constants/allConstants';
import {RecordHeader} from '../../pieces/recordHeader';
import {CompanyInfo} from './panels/companyInfo';
import {CompanyFinancial} from './panels/companyFinancial';
import {CompanySettings} from './panels/companySettings';
import {WorkersComp} from './panels/workersComp';
import {ServiceTitanInfo} from './panels/serviceTitanInfo';      // Thanh 01/04/24
import {ServiceTitanEmp} from './panels/serviceTitanEmp';      // Thanh 01/04/24
import {ServiceTitanNotEmp} from './panels/serviceTitanNotEmp';      // Thanh 01/04/24
import {ServiceTitanJob} from './panels/serviceTitanJob'; 
import {OwnerInfo} from './panels/ownerInfo';
import {RecordBodyWrapper} from '../wrappers/recordBodyWrapper';
import {RecordWrapper} from '../wrappers/recordWrapper';
import {RecordController} from '../../hooks/recordController';
import CompanyValidator from './validationSchemas';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, FormProvider} from 'react-hook-form';
import Utils from '../../utils/utils';
import base_url, {urlSearchParams} from '../../baseUrls';
import {COMPANY_FIELDS, ERROR_MSG, USER_FIELDS} from '../../constants/allConstants';
import {COMPANY_ELEMENTS, companyRules} from '../../permissions/companyAccessControl';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {useDispatch, useSelector} from 'react-redux';
import ApiUtils from '../../utils/apiUtils';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {COLORS} from '../../styles/styleConstants';
import EditFormButton from '../../pieces/editFormButton';
import actions from '../../redux/actions';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';
import {companyViewModes} from './companyViewModes';
import ConfirmModal from '../../pieces/modals/confirmPopup';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import {toast} from 'react-toastify';
import axios from 'axios';
import Loader from '../misc/loader';
import {isIpermitUser} from '../../permissions/utils';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import {validatePrices} from './companyUtils';
import {useGotIt} from '../../hooks/useGotIt';


const tabNames = {
  companySettings: 'Company Settings',
  companyInfo: 'Company Information',
  ownerInfo: 'Owner Information',
  workersComp: 'Workers Compensation',
  finance: 'Finance Fields',
  serviceTitanInfo: 'Service Titan Setup',
  serviceTitanEmp: 'Service Titan Employees',
  serviceTitanNotEmp: 'Not Service Titan Employee',
  serviceTitanJob: 'Service Titan Jobs',
};

const emptyAddress = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zipCode: '',
};

const createDefaultCompanyData = (companyData, mode, user, urlStateLicense)=> {

  if(mode === companyViewModes.ipermitInvite) {
    return {
      [COMPANY_FIELDS.CITY_INSPECTION.api_name]: false,
      [COMPANY_FIELDS.EMAIL_PREFERENCES.api_name]: companyData?.[COMPANY_FIELDS.EMAIL_PREFERENCES.api_name] || allConstants.EMAIL_PREFERENCES.EVERYONE.value,
    };
  }

  if(mode === companyViewModes.selfRegistering) {
    return {
      [USER_FIELDS.EMAIL.api_name]: null,
      [USER_FIELDS.PASSWORD.api_name]: null,
      [USER_FIELDS.CONFIRM_PASSWORD.api_name]: null,
      [COMPANY_FIELDS.ADMIN_INFO.api_name]: {
        [COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name]: null,
        [COMPANY_FIELDS.ADMIN_NAME_LAST.api_name]: null,
        [COMPANY_FIELDS.ADMIN_PHONE.api_name]: '',
      },
      [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: null,
      [COMPANY_FIELDS.DRIVER_LICENSE.api_name]: {
        [COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name]: null,
        [COMPANY_FIELDS.DRIVER_LICENSE_EXPIRES_AT.api_name]: null,
      },
      [COMPANY_FIELDS.WORKERS_COMP.api_name]: {
        [COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: allConstants.WORKERS_COMP_TYPES.REQUIRED.value,
        [COMPANY_FIELDS.WORKERS_COMP_CERT.api_name]: null,
        [COMPANY_FIELDS.WORKERS_COMP_CERT_EXP_DATE.api_name]: null,
      },
      [COMPANY_FIELDS.STATE_LICENSE.api_name]: {
        [COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]: urlStateLicense,
        [COMPANY_FIELDS.STATE_LICENSE_EXPIRES_AT.api_name]: null,
      },
      [COMPANY_FIELDS.EMAIL_PREFERENCES.api_name]: companyData?.[COMPANY_FIELDS.EMAIL_PREFERENCES.api_name] || allConstants.EMAIL_PREFERENCES.EVERYONE.value,
    };
  }

  const stateLicense = companyData?.[COMPANY_FIELDS.STATE_LICENSE.api_name]?
    {...companyData[COMPANY_FIELDS.STATE_LICENSE.api_name],
      ...{[COMPANY_FIELDS.STATE_LICENSE_FILE.api_name]:  companyData[COMPANY_FIELDS.STATE_LICENSE.api_name]?.fileId}} :{};


  const driversLicense = companyData?.[COMPANY_FIELDS.DRIVER_LICENSE.api_name]?
    {...companyData[COMPANY_FIELDS.DRIVER_LICENSE.api_name],
      ...{[COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name]:  companyData[COMPANY_FIELDS.DRIVER_LICENSE.api_name]?.fileId}} :{};

  const workerscomp = companyData?.[COMPANY_FIELDS.WORKERS_COMP.api_name]?
    {...companyData[COMPANY_FIELDS.WORKERS_COMP.api_name],
      ...{
        [COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: companyData?.[COMPANY_FIELDS.WORKERS_COMP.api_name]?.[COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name] || allConstants.WORKERS_COMP_TYPES.REQUIRED.value,
        [COMPANY_FIELDS.WORKERS_COMP_CERT.api_name]:  companyData[COMPANY_FIELDS.WORKERS_COMP.api_name]?.fileId}
    } :{[COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: allConstants.WORKERS_COMP_TYPES.REQUIRED.value};

  const paymentAuthForm = companyData?.[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name]?
    {...companyData[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name],
      ...{[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name]:  companyData[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name]?.fileId}} :{};

  let allowedTests = companyData?.[COMPANY_FIELDS.ALLOWED_TESTS.api_name];

  if(mode !== companyViewModes.verifyCompany && (!allowedTests || allowedTests.length === 0)) {
    companyData = {...companyData, [COMPANY_FIELDS.ALLOWED_TESTS.api_name]: [allConstants.TEST_TYPES.NONE.value]};
  }

  const company = {
    [COMPANY_FIELDS.ADMIN_INFO.api_name]: {
      [COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name]: null,
      [COMPANY_FIELDS.ADMIN_NAME_LAST.api_name]: null,
      [COMPANY_FIELDS.ADMIN_PHONE.api_name]:'',
    },

    [COMPANY_FIELDS.STATE_LICENSE.api_name]: stateLicense,
    [COMPANY_FIELDS.DRIVER_LICENSE.api_name]:driversLicense,
    [COMPANY_FIELDS.WORKERS_COMP.api_name]: workerscomp,

    [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: null,
    [COMPANY_FIELDS.ADDRESS.api_name]: companyData?.[COMPANY_FIELDS.ADDRESS.api_name] || emptyAddress,

    [COMPANY_FIELDS.OWNER.api_name]: {
      [COMPANY_FIELDS.ADDRESS.api_name]: companyData?.[COMPANY_FIELDS.OWNER.api_name]?.[COMPANY_FIELDS.ADDRESS.api_name] || emptyAddress,
    },

    [COMPANY_FIELDS.CITY_INSPECTION.api_name]: companyData?.[COMPANY_FIELDS.CITY_INSPECTION.api_name],

  };

  if(NewPermissionGate({
    user: user,
    elementPermissions: companyRules[COMPANY_ELEMENTS.financeInfoView],
  })) {
    company[COMPANY_FIELDS.PRICE_LIST.api_name] = companyData?.[COMPANY_FIELDS.PRICE_LIST.api_name] || [];
    company[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name] = paymentAuthForm;
  }

  if(!companyData) return {};

  return Utils.mergeDeep(company, companyData);

};

const getTabRequiredFields = (mode, isIpermit)=> {
  const validate = {
    [tabNames.companySettings]: [],
    [tabNames.companyInfo]:[
      COMPANY_FIELDS.STATE_LICENSE.api_name,
      COMPANY_FIELDS.PAYMENT_METHOD.api_name,
    ],
    [tabNames.workersComp]:[
      COMPANY_FIELDS.WORKERS_COMP.api_name,
    ],
    [tabNames.ownerInfo]: [
      `${COMPANY_FIELDS.DRIVER_LICENSE.api_name}`,
    ],
    [tabNames.serviceTitanInfo]: [],     // Thanh 01/04/24
    [tabNames.serviceTitanEmp]: [],     // Thanh 01/04/24
    [tabNames.serviceTitanNotEmp]: [],     // Thanh 01/04/24
    [tabNames.serviceTitanJob]: [],     // Thanh 01/04/24
    [tabNames.finance]:[COMPANY_FIELDS.PRICE_LIST.api_name, COMPANY_FIELDS.QB_EMAIL.api_name],
  };

  const companySettingsValidation = [
    COMPANY_FIELDS.NAME.api_name, COMPANY_FIELDS.EMAIL.api_name,
    COMPANY_FIELDS.LOCATION.api_name, COMPANY_FIELDS.ALLOWED_TESTS.api_name,
    COMPANY_FIELDS.ALLOWED_PERMITS.api_name,
    COMPANY_FIELDS.STATE_LICENSE.api_name
  ];

  switch (mode) {
  case companyViewModes.selfRegistering: {
    validate[tabNames.companyInfo] = [
      `${COMPANY_FIELDS.NAME.api_name}`,
      `${COMPANY_FIELDS.ADMIN_INFO.api_name}`,
      COMPANY_FIELDS.STATE_LICENSE.api_name,
      // `${COMPANY_FIELDS.ADMIN_NAME.api_name}.${COMPANY_FIELDS.ADMIN_NAME_LAST.api_name}`,
      `${COMPANY_FIELDS.PAYMENT_METHOD.api_name}`,
      `${COMPANY_FIELDS.PHONE_NUMBER.api_name}`,
      `${COMPANY_FIELDS.EMAIL.api_name}`,
      USER_FIELDS.PASSWORD.api_name,
      USER_FIELDS.CONFIRM_PASSWORD.api_name
    ];
    break;
  }
  case companyViewModes.ipermitInvite: {
    validate[tabNames.companySettings] = [
      COMPANY_FIELDS.NAME.api_name, COMPANY_FIELDS.EMAIL.api_name,
      COMPANY_FIELDS.LOCATION.api_name, COMPANY_FIELDS.ALLOWED_TESTS.api_name,
      COMPANY_FIELDS.ALLOWED_PERMITS.api_name,
      COMPANY_FIELDS.STATE_LICENSE.api_name
    ];
    validate[tabNames.companyInfo] = [];
    validate[tabNames.ownerInfo] = [];
    validate[tabNames.workersComp] = [];
    validate[tabNames.serviceTitanInfo] = [];   // Thanh 01/04/24
    break;
  }
  case companyViewModes.verifyCompany: {
    validate[tabNames.companySettings] = [COMPANY_FIELDS.NAME.api_name, COMPANY_FIELDS.EMAIL.api_name,
      COMPANY_FIELDS.LOCATION.api_name, COMPANY_FIELDS.ALLOWED_TESTS.api_name,
      COMPANY_FIELDS.ALLOWED_PERMITS.api_name
    ];
    validate[tabNames.companyInfo] = [...validate[tabNames.companyInfo], ...[
      `${COMPANY_FIELDS.NAME.api_name}`,
      `${COMPANY_FIELDS.PAYMENT_METHOD.api_name}`,
    ]];
    break;
  }
  case companyViewModes.completeOnboarding: {
    validate[tabNames.companyInfo].push(`${COMPANY_FIELDS.ADMIN_INFO.api_name}`);
    validate[tabNames.companyInfo].push(`${COMPANY_FIELDS.PAYMENT_METHOD.api_name}`);
    break;
  }
  default: {
    if (isIpermit) {
      validate[tabNames.companySettings] = companySettingsValidation;
    }
    break;
  }
  }
  return validate;
};

export const CompanyView = (props)=> {
  const fileUpoadFieldKeys = [
    `${COMPANY_FIELDS.STATE_LICENSE.api_name}.${COMPANY_FIELDS.STATE_LICENSE_FILE.api_name}`,
    `${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_CERT.api_name}`,
    `${COMPANY_FIELDS.DRIVER_LICENSE.api_name}.${COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name}`,
    `${COMPANY_FIELDS.AUTH_LETTER.api_name}.${COMPANY_FIELDS.AUTH_LETTER_FILE.api_name}`,
  ];
  const gotIt = useGotIt();
  const [ isSaving, setIsSaving ] = useState(false);

  const [submitInProgress, setSubmitInProgress] = useState(false);
  const isRegistering = React.useRef(props.isRegistering);
  const stateLicenseNumber = Utils.getUrlParameter(urlSearchParams.stateLicenseNumber);
  const options = {
    markets: useAutocompleteOptions({field: COMPANY_FIELDS.LOCATION.api_name}).options
  };

  const isSelfRegistering = ()=> {
    return isRegistering.current && Utils.valueIsSpecified(stateLicenseNumber);
  };
  const getInitialState = ()=> {
    const defaultCompanyData = {
      [COMPANY_FIELDS.WORKERS_COMP.api_name]: {
        [COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: allConstants.WORKERS_COMP_TYPES.REQUIRED.value
      }
    };
    if(isSelfRegistering()) {
      return{...defaultCompanyData, ...{
        [COMPANY_FIELDS.STATE_LICENSE.api_name]: {
          [COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]: stateLicenseNumber,
        }
      }};
    }
    return defaultCompanyData;
  };

  const user = useSelector(state=> state.user);
  const stateCompany = useSelector(state=> state.company);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const needServiceTitanData = useRef(false);

  const [company, setCompany] = useState(getInitialState());

  const isCompleteonboarding = React.useRef(Utils.textToBool(Utils.getUrlParameter(urlSearchParams.completeOnboarding)));

  const isIpermitInvite = ()=> {
    if(Utils.getUrlParameter(urlSearchParams.invite)) return true;
    return isRegistering.current && !stateLicenseNumber;
  };

  const companyId = Utils.getUrlParameter(urlSearchParams.companyId);

  const [showRejectPopup, setShowRejectPopup] = useState(false);

  const verificationProcess =React.useRef(false);

  const getCurrentMode = ()=> {
    if(isSelfRegistering()) return companyViewModes.selfRegistering;
    if(isIpermitInvite()) return companyViewModes.ipermitInvite;
    if(isCompleteonboarding.current) return companyViewModes.completeOnboarding;
    if(verificationProcess.current || company?.[COMPANY_FIELDS.STATUS.api_name] === allConstants.COMPANY_STATUSES.PENDING.value) return companyViewModes.verifyCompany;
    return companyViewModes.general;
  };

  const initialViewMode = (currentMode)=> {

    if(currentMode === companyViewModes.selfRegistering ||
      currentMode === companyViewModes.ipermitInvite ||
      currentMode === companyViewModes.completeOnboarding) return false;
    return true;
  };
  const currentMode = getCurrentMode();
  // const [hasErrors, setHasErrors] = useState(currentMode === companyViewModes.selfRegistering ? [0,1,2]:[]);
  const [viewMode, toggleViewMode] = useState(initialViewMode(currentMode));

  const companyValidator = React.useRef(new CompanyValidator({
    isSelfRegistering: isSelfRegistering(),
    isIpermitInvite: isIpermitInvite(),
    id: companyId,
    uniqueEmail: currentMode !== companyViewModes.completeOnboarding && currentMode!== companyViewModes.verifyCompany

  }));



  const validationSchema = companyValidator.current.getValidationSchema(getTabRequiredFields(currentMode, isIpermitUser(user?.role) && !user.companyId), currentMode === isIpermitInvite());
  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    criteriaMode:'all',
    defaultValues: createDefaultCompanyData(company,
      isSelfRegistering()? companyViewModes.selfRegistering : isIpermitInvite() ? companyViewModes.ipermitInvite : companyViewModes.existingCompany,
      user, stateLicenseNumber
    )
  };

  const formMethods = React.useRef(useForm(formOptions));
  /**
   * Set company data as default values to react-hook-form
   */
  useEffect(()=> {

    const companyStatus  = company?.[COMPANY_FIELDS.STATUS.api_name];
    if(company && companyStatus && companyStatus === allConstants.COMPANY_STATUSES.PENDING.value){
      verificationProcess.current = true;
    }
    if(company && companyStatus && companyStatus === allConstants.COMPANY_STATUSES.NOT_SIGNED.value && !Object.values(allConstants.IPERMIT_ROLES).includes(user[allConstants.USER_FIELDS.ROLE.api_name]) &&
      user.companyId){

      isCompleteonboarding.current = true;
      if(viewMode) {
        toggleViewMode(false);
      }

    }

  }, [company]);

  useEffect(()=> {
    if(formMethods.current) {

      // Thanh 02/04/24
      //console.log('get default company data');

      formMethods.current.reset(createDefaultCompanyData(company, currentMode, user, stateLicenseNumber));
    }
  }, [viewMode, company]);

  useEffect(()=> {
    formMethods.current.clearErrors();
  }, [viewMode]);

  useEffect(()=> {
    const currentId = company ? company?.id : undefined;
    if(!currentId && stateCompany.id) {
      setCompany(stateCompany);
    }
  }, [stateCompany]);

  useEffect(()=> {
    sessionStorage.clear();
    return ()=> {
      sessionStorage.clear();
      formMethods.current.reset();
    };
  }, []);

  if(Utils.isDevEnv()) {
    window.companyForm = formMethods.current;
  }

  const getDefaultProps = (isEditable)=> {
    const inputDefaultProps = {
      viewMode: viewMode,
      isEditable: (isSelfRegistering() || isIpermitInvite() || isCompleteonboarding.current) ? true : isEditable,
      renderLabel: true,
      nested: false,
      parent: null,
    };
    const tabProps = {
      isSelfRegistering: isSelfRegistering(),
      isIpermitInvite: isIpermitInvite(),
      isCompleteonboarding: isCompleteonboarding.current,
      companyData: company,
      user: user,
      companyViewMode: getCurrentMode(),
      dontUploadImmediately: getCurrentMode() === companyViewModes.selfRegistering,
      options: options,
      notAllowedToEdit: (isSelfRegistering() || isIpermitInvite() || isCompleteonboarding.current)?false : (!isEditable && !viewMode)
    };
    return {inputDefaultProps, tabProps};
  };

  const onCancel = async (e)=> {
    e.preventDefault();
    const initial = createDefaultCompanyData(company, isSelfRegistering()? companyViewModes.selfRegistering : isIpermitInvite() ? companyViewModes.ipermitInvite : companyViewModes.existingCompany);
    await formMethods.current.reset(initial);

    if(isSelfRegistering()) {
      navigate(allConstants.PATH.SIGN_UP);
      return;
    }
    if(isIpermitInvite() || verificationProcess.current) {
      navigate(allConstants.PATH.ALL_COMPANIES);
      return;
    }
    toggleViewMode(true);
  };

  /**
   * Switch to edit mode
   */
  const toggleEditMode = ()=> {
    const elementPermissions = verificationProcess.current ? companyRules[COMPANY_ELEMENTS.verify] : companyRules[COMPANY_ELEMENTS.editButton];
    const noPermissionMessage = `You do not have permission to ${verificationProcess.current ? 'verify' : 'edit'} companies`;

    if(NewPermissionGate({
      user: user,
      elementPermissions: elementPermissions,
    })) {
      toggleViewMode(false);
    } else {
      gotIt.setShow(noPermissionMessage);
    }
  };

  const getTabs = ()=> {
    const tabRequiredFields = getTabRequiredFields(getCurrentMode(), isIpermitUser(user?.role) && !user.companyId);
    const tabs = {companySettings: {name: tabNames.companySettings, api_name: 'companySettings',
      panel: <CompanySettings {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.companySettings],
      }))}
      />,
      order: 0,
      access: companyRules[COMPANY_ELEMENTS.companySettings],
      fieldsToValidate: tabRequiredFields[tabNames.companySettings]
    },
    companyInfo: {name: tabNames.companyInfo, api_name: 'companyInfo',
      panel: <CompanyInfo {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.companyInfo]
      }))}/>,
      order: 1,
      access: companyRules[COMPANY_ELEMENTS.companyInfo],
      fieldsToValidate: tabRequiredFields[tabNames.companyInfo]
    },
    ownerInfo: {name: tabNames.ownerInfo, api_name: 'ownerInfo',
      panel: <OwnerInfo {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.ownerInfo]
      }))}/>,
      order: 2,
      access: companyRules[COMPANY_ELEMENTS.ownerInfo],
      fieldsToValidate: tabRequiredFields[tabNames.ownerInfo]
    },
    workersComp: {name: tabNames.workersComp, api_name: 'workersComp',
      panel: <WorkersComp {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.workersInfo]
      }))}/>,
      order: 3,
      access: companyRules[COMPANY_ELEMENTS.workersInfo],
      fieldsToValidate: tabRequiredFields[tabNames.workersComp]
    },
    // Thanh 01/04/24
    serviceTitanInfo: {name: tabNames.serviceTitanInfo, api_name: 'serviceTitanInfo',
      panel: <ServiceTitanInfo {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.serviceTitanInfo]
      }))} getServiceTitanData={getServiceTitanData} />,
      order: 5,
      access: companyRules[COMPANY_ELEMENTS.serviceTitanInfo],
      fieldsToValidate: tabRequiredFields[tabNames.serviceTitanInfo]
    },
    serviceTitanEmp: {name: tabNames.serviceTitanEmp, api_name: 'serviceTitanEmp',
      panel: <ServiceTitanEmp {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.serviceTitanEmp]
      }))} getServiceTitanData={getServiceTitanData} />,
      order: 6,
      access: companyRules[COMPANY_ELEMENTS.serviceTitanEmp],
      fieldsToValidate: tabRequiredFields[tabNames.serviceTitanEmp]
    },
    serviceTitanNotEmp: {name: tabNames.serviceTitanNotEmp, api_name: 'serviceTitanNotEmp',
      panel: <ServiceTitanNotEmp {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.serviceTitanNotEmp]
      }))} getServiceTitanData={getServiceTitanData} />,
      order: 7,
      access: companyRules[COMPANY_ELEMENTS.serviceTitanNotEmp],
      fieldsToValidate: tabRequiredFields[tabNames.serviceTitanNotEmp]
    },
    serviceTitanNotJob: {name: tabNames.serviceTitanJob, api_name: 'serviceTitanJob',
      panel: <ServiceTitanJob {...getDefaultProps(NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.serviceTitanJob]
      }))} getServiceTitanData={getServiceTitanData} />,
      order: 8,
      access: companyRules[COMPANY_ELEMENTS.serviceTitanJob],
      fieldsToValidate: tabRequiredFields[tabNames.serviceTitanJob]
    },
    };
    if(NewPermissionGate({
      user,
      elementPermissions: companyRules[COMPANY_ELEMENTS.financeInfoView],
    })) {
      //add financial tab for roles who can see it
      tabs.finance = {name: tabNames.finance, api_name: 'finance',
        panel: <CompanyFinancial {...getDefaultProps(NewPermissionGate({
          user: user,
          elementPermissions: viewMode ? companyRules[COMPANY_ELEMENTS.financeInfoView] : companyRules[COMPANY_ELEMENTS.financeInfoEdit]
        }))}/>,
        order: 4,
        access: companyRules[COMPANY_ELEMENTS.financeInfoView],
        fieldsToValidate: tabRequiredFields[tabNames.finance]
      };
    }
    return tabs;
  };

  const getCurrentTabs = ()=> {
    const tabs = getTabs();
    const currTabs = {...tabs};
    if(isIpermitInvite()) {
      currTabs.companyInfo.fieldsToValidate = currTabs.companyInfo.fieldsToValidate.filter((f)=> {
        return f !== COMPANY_FIELDS.NAME.api_name && f !== COMPANY_FIELDS.EMAIL.api_name;
      });
      return currTabs;
    } else if(isSelfRegistering()) {

      delete currTabs.companySettings;
      delete currTabs.finance;
      return currTabs;
    }

    const currentTabs ={};
    for (const [key, value] of Object.entries(tabs)) {

      if(NewPermissionGate({
        user: user,
        elementPermissions: value.access,
      }
      )){
        currentTabs[key] = value;
      }
    }
    return currentTabs;
  };

  //const headerHeight = isIpermitInvite() || isSelfRegistering() ? '50px' : '75px';

  // Thanh 02/04/24
  const headerHeight = isIpermitInvite() || isSelfRegistering() ? '50px' : '50px';

  const getValue = (field)=> {
    return company?.[field.api_name]||'';
  };

  const userCanEditCompany = ()=> {

    const editableStatuses = [
      allConstants.COMPANY_STATUSES.ACTIVE.value,
      allConstants.COMPANY_STATUSES.PENDING.value,
      allConstants.COMPANY_STATUSES.INVITED.value,
      allConstants.COMPANY_STATUSES.NOT_SIGNED.value
    ];

    if(company?.[allConstants.COMPANY_FIELDS.IS_BLOCKED.api_name]) return  false;
    if(editableStatuses.includes(company?.[allConstants.COMPANY_FIELDS.STATUS.api_name])) {
      return NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.editButton]
      });
    } else {
      return false;
    }
  };

  const onReject = async ()=> {
    const responseStatus = await dispatch(actions.rejectCompanyRegistration({companyId: company[COMPANY_FIELDS.ID.api_name]}));
    unwrapRequestResult({
      showSuccess: true,
      successMessage: 'Rejected company was deleted.',
      result: responseStatus,
      id: 'reject_company',
    });
    if(responseStatus?.meta?.requestStatus === 'fulfilled') {
      handleSuccess(true);
    }
  };

  const handleSuccess = (returnToAllCompanies)=> {
    if(isSelfRegistering()) {
      dispatch(actions.getCurrentUserData());
      setSubmitInProgress(false);
      navigate(allConstants.PATH.COMPANY_PENDING, {replace: true});
    }
    if(isIpermitInvite() || returnToAllCompanies) {
      navigate(allConstants.PATH.ALL_COMPANIES, {replace: true});
    }
    toggleViewMode(true);
  };

  const formHasFiles= ()=> {
    let hasFiles = false;
    fileUpoadFieldKeys.forEach((key)=> {
      const itemInSessionStorage = sessionStorage.getItem(key);
      if(itemInSessionStorage) {
        hasFiles = true;
      }
    });
    return hasFiles;
  };

  const fileUpload = async (file)=> {
    const fileData = new FormData();
    fileData.append('file', file);
    let url = `${base_url.api}files/upload`;
    const authToken = await ApiUtils.getAccessToken();
    return axios.post(url, fileData, {
      headers: {
        Authorization: authToken,
      }});
  };

  const uploadFilesOfPendingCompany = async (companyId, unwrappedCompany)=> {
    const fields = [];
    if(!companyId)  {
      handleSuccess();
      return;
    }
    if(formHasFiles()) {
      let promises = [];
      for (const key of fileUpoadFieldKeys) {
        const itemInSessionStorage = sessionStorage.getItem(key);
        if(itemInSessionStorage) {
          const fileName = sessionStorage.getItem(`${key}_name`);
          const blob = Utils.dataURItoBlob(itemInSessionStorage);
          const file = new File([blob], fileName || key.split('.')[0], {type: blob.type});
          fields.push(key);
          promises.push(fileUpload(file));
          sessionStorage.removeItem(`${key}_name`);
          sessionStorage.removeItem(key);
        }
      }
      const result = await Promise.allSettled(promises);
      const failed = [];
      const editCompanyData = {};
      result.forEach((r, i)=> {
        if(r.status === 'rejected') {
          failed.push(fields[i]);
        }
        if(r.status === 'fulfilled') {

          const keys = fields[i].split('.');
          editCompanyData[keys[0]] = keys.length === 1 ? r.value.data.data.id : {
            [keys[1]]: r.value.data.data.id
          };
        }
      });
      if(Object.keys(editCompanyData).length > 0) {
        const payload = {
          ...editCompanyData, ...{
            companyId: companyId,
          }
        };

        const editResult = await dispatch(actions.editCompany(payload));

        if(editResult?.meta?.requestStatus === 'rejected') {
          const warningMessage = 'Company was successfully registered, but files were not uploaded.';
          toast(warningMessage, {type: 'warning', toastId: 'company_registration'});
        }
        else if(failed.length > 0) {
          const failedFields = failed.map((f)=> f.split('.')[0]);
          const warningMessage = `Company was successfully registered, but some files were not uploaded: ${failedFields.join(', ')}`;
          toast(warningMessage, {type: 'warning', toastId: 'company_registration'});
        } else {
          toast('Company was successfully registered', {type: 'success', toastId: 'company_registration'});
        }
        handleSuccess();
      }
      console.log(result.map(promise => promise));
    }
    else {
      console.log('No files to upload');
      handleSuccess();
    }
  };



  const onSubmit = async (data) => {

    
    //console.log('on submit');

    if(ApiUtils.isMockMode()) {
      return;
    }

    setIsSaving(true);

    
    let sanitizedData = Utils.sanitizeFileUploadFields(data);

    // Thanh 01/04/24
    //console.log('on submit data ');
    //console.log(sanitizedData);
    
    //delete sanitizedData['serviceTitan']['employees'];
    //delete sanitizedData['serviceTitan']['notEmployees'];
    //delete sanitizedData['serviceTitan']['tagNames'];
    

    if (sanitizedData['serviceTitan'] != undefined) {
      sanitizedData['serviceTitan']['tenantId'] = Utils.removeTabsAndSpaces(sanitizedData['serviceTitan']['tenantId']);
      sanitizedData['serviceTitan']['tenantClientId'] = Utils.removeTabsAndSpaces(sanitizedData['serviceTitan']['tenantClientId']);   
      sanitizedData['serviceTitan']['tenantClientSecret'] = Utils.removeTabsAndSpaces(sanitizedData['serviceTitan']['tenantClientSecret']);    
    }
    //console.log('need service titan data => ' + needServiceTitanData.current);

    sanitizedData['getServiceTitanData'] =  needServiceTitanData.current;
 
    //console.log('after remove properties');
    //console.log(sanitizedData);
    

    let invalidPrices = validatePrices(data[COMPANY_FIELDS.PRICE_LIST.api_name]);
    if(invalidPrices.length !== 0) {
    
      setIsSaving(false);
      invalidPrices.forEach((el, index)=> {
        formMethods.current.setError(`${COMPANY_FIELDS.PRICE_LIST.api_name}.[${el}].price`, {type: 'custom', message: ERROR_MSG.PRICE_SHOULD_BE_A_NUMBER});
      });
      return;
    }

    let responseStatus;
    let successMessage;

    if (isCompleteonboarding.current) {
      successMessage = 'Sign up completed. Now you can create jobs.';
      responseStatus = await dispatch(actions.completeOnboarding({company: sanitizedData}));
      dispatch(actions.getCurrentUserData());


    } else if(isIpermitInvite()){
      successMessage = 'Company is invited.';
      responseStatus = await dispatch((actions.inviteCompany(sanitizedData)));


    } else if(isSelfRegistering()){
      setSubmitInProgress(true);
      responseStatus = await dispatch(actions.selfRegisterCompany({...sanitizedData, ...{
        user: {
          [allConstants.USER_FIELDS.FIRST_NAME.api_name]: sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name],
          [allConstants.USER_FIELDS.LAST_NAME.api_name]: sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.ADMIN_NAME_LAST.api_name],
          [allConstants.USER_FIELDS.EMAIL.api_name]: sanitizedData[allConstants.USER_FIELDS.EMAIL.api_name],
          [allConstants.USER_FIELDS.PASSWORD.api_name]: sanitizedData[allConstants.USER_FIELDS.PASSWORD.api_name],
          [allConstants.USER_FIELDS.PHONE_NUMBER.api_name]:sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.PHONE_NUMBER.api_name],
          [allConstants.USER_FIELDS.PHONE_NUMBER.api_name]:sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.PHONE_NUMBER.api_name],

        }
      }}));

      //console.log('response status');
      //console.log(responseStatus);

      if(responseStatus.meta.requestStatus === 'fulfilled') {
        const companyId = responseStatus.payload.company.id;
        await uploadFilesOfPendingCompany(companyId);

      } else if(responseStatus.meta.requestStatus === 'rejected') {
        setSubmitInProgress(false);
        setIsSaving(false);
        toast.error(responseStatus.payload.message, {
          toastId: 'error_onregister'
        });
      }
      return;

    } else if(verificationProcess.current){
      responseStatus = await dispatch(actions.acceptCompanyRegistration({...sanitizedData, ...{
        companyId: company[allConstants.COMPANY_FIELDS.ID.api_name],
      }
      }));

    } else {
      //don't send financial fields if user doesn't have permission to edit them
      const financialFields = [
        COMPANY_FIELDS.CLASSIFICATION.api_name,
        COMPANY_FIELDS.PAYMENT_FEE.api_name,
        COMPANY_FIELDS.QB_EMAIL.api_name,
        COMPANY_FIELDS.QB_ID.api_name,
        COMPANY_FIELDS.PRICE_LIST.api_name,
      ];
      //console.log('User is editing company', user);
      if(!NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.financeInfoEdit],
      })) {
        console.log('User does not have permission to edit financial fields');
        Object.keys(sanitizedData).forEach((key) => {
          if(financialFields.includes(key)) {
            delete sanitizedData[key];
          }
        });
      }
      if(!NewPermissionGate({
        user: user,
        elementPermissions: companyRules[COMPANY_ELEMENTS.editPaymentMethod],
      })) {
        delete sanitizedData[COMPANY_FIELDS.PAYMENT_METHOD.api_name];
      }

      const payload = {
        ...sanitizedData, ...{
          companyId: company[allConstants.COMPANY_FIELDS.ID.api_name]}
      };

      // Thanh 01/04/24
      //console.log('edit company payload - Thanh');
      //console.log(payload);

      successMessage = 'Company successfully updated.';
      responseStatus = await dispatch(actions.editCompany(payload));
    }

    setIsSaving(false);
    if(responseStatus) {
      const unwrapped = unwrapRequestResult({
        showSuccess: true,
        result: responseStatus,
        successMessage: successMessage,
        id: 'company_update',
      });
      if(unwrapped && Utils.objectHasProperty(unwrapped, 'company') && unwrapped.company) {
        //merge with current company object to make sure we display company admin name - it is not returned in edit company response.
    
        //console.log('new data for unwarpped company');
        //console.log(unwrapped.company);

        setCompany({...company, ...unwrapped.company});
      }

      needServiceTitanData.current = false;
      // if (needServiceTitanData.current) {
      //   needServiceTitanData.current = false;
      //   toggleViewMode(true);
      // }
      // else {
        if(unwrapped?.status !== 'error') {
          toggleViewMode(true);
        }
        if(responseStatus?.meta?.requestStatus === 'fulfilled') {
          handleSuccess();
        }
      //}

    }
  };

  // Thanh 02/04/24
  const getServiceTitanData = async() => {
 
    needServiceTitanData.current = true;
    await onSubmit(formMethods.current.getValues());
  }

  //if dev env bind company to window object
  if(process.env.NODE_ENV === 'development') {
    window.compantState = company;
  }


  const hideNavbar = isSelfRegistering();


  if(submitInProgress) {
    return <Loader timeout={50}/>;
  }

  return (
    <RecordController
      recordId={companyId || null}
      module={'companies'}
      responseTarget={'company'}
      saveRecord={(data)=>setCompany(data)}
      viewMode={viewMode}
    >
      <RecordWrapper
        hideNavbar={hideNavbar}
        headerHeight={headerHeight}

        header={
          <Box display={'flex'} flexDirection={'column'}  id={'headercontainer'} justifyContent={'center'}
             sx={{width:'100%'}}>
            <Box display={'flex'} flexDirection={'row'}  justifyContent={'center'} sx={{
              width: '100%'}}>
              <Box display={'flex'} flexDirection={'row'} width={'100%'} height={'50px'}>
                {company && <ConfirmModal
                  open={showRejectPopup}
                  // header={'Reject company registration'}
                  handleClose={()=> setShowRejectPopup(false)}>
                  <Box sx={{
                    '& .MuiTypography-root': {
                      fontSize: '1rem'
                    }}}>
                    <Typography variant={'body1'} color={COLORS.globalRed} mb={2}>Rejected company will be deleted from the portal!</Typography>
                    <Typography variant={'body1'} mb={3}>{'Reject '}
                      <Typography component={'span'} fontWeight={600} color={COLORS.globalBlue}>{company?.[COMPANY_FIELDS.NAME.api_name]}</Typography>?
                    </Typography>

                    <FormSubmitButtons
                      cancelText={'No'}
                      submitText={'Yes'}
                      cancelAction={()=> setShowRejectPopup(false)}
                      submitAction={onReject}
                    />


                  </Box>
                </ConfirmModal>}
                <Box display={'flex'}
                  width={'calc(100% - 110px)'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  gap={0.5}
                >
                  <RecordHeader
                    text={isSelfRegistering() ? 'Register' : isIpermitInvite() ? 'Invite Company' : getValue(COMPANY_FIELDS.NAME)}
                    headerHeight={headerHeight}
                  />
                    {getValue(COMPANY_FIELDS.EMAIL) &&
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <EmailOutlinedIcon sx={{color: COLORS.globalBlue, marginRight: '8px'}}/>
                    <Typography component={'span'} sx={{
                      fontStyle: 'italic'
                    }}>{getValue(COMPANY_FIELDS.EMAIL)}</Typography>
                  </Box>}
                </Box>
                {ApiUtils.isDevelopment() && <Box sx={{position: 'absolute',
                    left: '20px',
                    top: '20px'}}>
                    <Button variant="contained"
                      color={'secondary'}
                      onClick={async ()=> {
                        await formMethods.current.trigger();
                      }}

                    >Show errors</Button>
                </Box>}
                {userCanEditCompany() && <Box display={'flex'} sx={{width:'110px'}}>
                    <EditFormButton onClick={viewMode ? toggleEditMode : onCancel} onCancel={onCancel} edit={viewMode}
                      label={verificationProcess.current ? 'Verify' : null}
                    />
                </Box>}
              </Box>
            </Box>
            {isSaving && <Box display={'flex'} flexDirection={'row'} width={'100%'} height={'100px'}
                 justifyContent={'center'}
                sx={{width: '100%'}}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <CircularProgress variant="indeterminate"  color="success" />
                  <Typography color="red" variant="h6">
                      Saving . . .  Please wait!
                  </Typography>                
                </Box>
            </Box>}
          </Box>
        }
      >
        {gotIt.PopUp()}

        <FormProvider {...formMethods.current}>
          <form onSubmit={formMethods.current.handleSubmit(onSubmit)}>
            {/*render main content*/}
            <RecordBodyWrapper
              tabs={getCurrentTabs()}
              headerHeight={headerHeight}
              hideNavbar={hideNavbar}
              submitBlockOptions={{
                show: isIpermitInvite() || isSelfRegistering() || !viewMode,
                cancelText: 'Cancel',
                submitText: isSelfRegistering() ? 'Register' : isIpermitInvite() ? 'Invite' : verificationProcess.current ? 'Verify':'Update',
                cancelAction: onCancel,
                extraButton: {
                  show: verificationProcess.current,
                  text: 'Reject this company',
                  type: 'button',
                  action: (()=> setShowRejectPopup(true)),
                  variant: 'outlined'
                }
              }}
              goBack={{
                show: NewPermissionGate({
                  user: user,
                  elementPermissions: companyRules[COMPANY_ELEMENTS.allCompaniesNavLink],
                }),
                path: allConstants.PATH.ALL_COMPANIES,
                view: 'companies'
              }}
            >
            </RecordBodyWrapper>
          </form>
        </FormProvider>
      </RecordWrapper>
    </RecordController>

  );
};

CompanyView.propTypes = {
  isRegistering: PropTypes.bool
};

CompanyView.defaultProps = {
  isRegistering: false
};
