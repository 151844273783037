const COMPANY_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'name', required: true, label: 'Company Name', display_label: 'Company Name'},
  EMAIL: {api_name: 'email', required: true, label: 'Email', display_label: 'Email'},
  STATUS: {api_name: 'status', required: true, label: 'Status', display_label: 'Status'},
  IS_BLOCKED: {api_name: 'isBlocked', required: false, label: 'is blocked', display_label: 'is blocked'},
  IS_SERVICE_TITAN: {api_name: 'isServiceTitan', required: false, label: 'Srv Titan?', display_label: 'Srv Titan?'},
  PHONE_NUMBER: {api_name: 'phoneNumber', required: false, label: 'Phone No.', display_label: 'Phone No.'},
  ADDRESS: {api_name: 'address', required: false, label: 'Location Information', display_label: 'Location Information'},
  ADDRESS_LINE_1: {api_name: 'line1', required: false, label: 'Street Address', display_label: 'Street Address'},
  ADDRESS_LINE_2: {api_name: 'line2', required: false, label: 'Street Address 2', display_label: 'Street Address 2'},
  ADDRESS_CITY: {api_name: 'city', required: false, label: 'City', display_label: 'City'},
  ADDRESS_STATE: {api_name: 'state', required: false, label: 'State', display_label: 'State'},
  ADDRESS_ZIPCODE: {api_name: 'zipCode', required: false, label: 'Zip Code', display_label: 'Zip Code'},
  MAIL_PACKET_TO: {api_name: 'mailPacketTo', required: false, label: 'Mail Packet To', display_label: 'Mail Packet To'},
  CHEERS_USERNAME: {api_name: 'cheersUsername', required: false, label: 'Cheers Username', display_label: 'Cheers Username'},
  MAIN_CONTACT: {api_name:'mainContact', required: false, label: 'Main Contact', display_label: 'Main Contact'},
  COVER_SHEET: {api_name:'coverSheet', required: false, label: 'Cover Sheet', display_label: 'Cover Sheet'},


  STATE_LICENSE: {api_name: 'stateLicense', required: false, label: 'State License', display_label: 'State License'},
  STATE_LICENSE_FILE: {api_name: 'fileUploadId', required: false, label: 'State License Document', display_label: 'State License Document'},
  STATE_LICENSE_EXPIRES_AT: {api_name: 'expiresAt', required: false, label: 'State License Expiration Date', display_label: 'State License Expiration Date'},
  STATE_LICENSE_NUMBER: {api_name: 'licenseNumber', required: true, label: 'State License Number', display_label: 'State License Number'},

  DRIVER_LICENSE: {api_name: 'driverLicense', required: false, label: 'Driver License Information', display_label: 'Driver License Information'},
  DRIVER_LICENSE_FILE: {api_name: 'fileUploadId', required: false, label: 'Driver License Document', display_label: 'Driver License Document'},
  DRIVER_LICENSE_EXPIRES_AT: {api_name: 'expiresAt', required: false, label: 'Driver License Expiration Date', display_label: 'Driver License Expiration Date'},
  DRIVER_LICENSE_NUMBER: {api_name: 'licenseNumber', required: false, label: 'Driver License Number', display_label: 'Driver License Number'},

  AUTH_LETTER: {api_name: 'authorizationLetter', required: false, label: 'Auth. Letter', display_label: 'Auth. Letter'},
  AUTH_LETTER_FILE: {api_name: 'fileUploadId', required: false, label: 'Auth. Letter Document', display_label: 'Auth. Letter Document'},
  AUTH_LETTER_EXPIRES_AT: {api_name: 'expiresAt', required: false, label: 'Auth Letter Expiration Date', display_label: 'Auth Letter Expiration Date'},

  FEDERAL_ID: {api_name: 'federalId', required: false, label: 'Federal ID No.', display_label: 'Federal ID No.'},

  OWNER: {api_name: 'owner', required: false, label: 'Owner', display_label: 'Owner'},

  OWNER_NAME: {api_name: 'firstName', required: false, label: 'Owner\'s Name', display_label: 'Owner\'s Name'},
  OWNER_ADDRESS: {api_name: 'address', required: false, label: 'Owner\'s Location Information', display_label: 'Owner\'s Location Information'},
  OWNER_ADDRESS_LINE_1: {api_name: 'line1', required: false, label: 'Owner Information', display_label: 'Owner Information'},
  OWNER_ADDRESS_LINE_2: {api_name: 'line2', required: false, label: 'Owner Information', display_label: 'Owner Information'},
  OWNER_ADDRESS_CITY: {api_name: 'city', required: false, label: 'Owner Information', display_label: 'Owner Information'},
  OWNER_ADDRESS_STATE: {api_name: 'state', required: false, label: 'Owner Information', display_label: 'Owner Information'},
  OWNER_ADDRESS_ZIPCODE: {api_name: 'zipCode', required: false, label: 'Owner Information', display_label: 'Owner Information'},
  OWNER_PHONE_NUMBER: {api_name: 'phoneNumber', required: false, label: 'Owner\'s Phone No.', display_label: 'Owner\'s Phone No.'},

  WORKERS_COMP: {api_name: 'workersComp', required: false, label: 'workers compensation', display_label: 'workers compensation'},
  WORKERS_COMP_AGENT_NAME: {api_name: 'agentName', required: false, label: 'Agent Name', display_label: 'Agent Name'},
  WORKERS_COMP_AGENT_PHONE: {api_name: 'agentPhoneNumber', required: false, label: 'Agent Phone No.', display_label: 'Agent Phone No.'},
  WORKERS_COMP_CERT: {api_name: 'fileUploadId', required: false, label: 'Certificate', display_label: 'Certificate'},
  WORKERS_COMP_COMPANY: {api_name: 'company', required: false, label: 'Company', display_label: 'Company'},
  WORKERS_COMP_CERT_EXP_DATE: {api_name: 'expiresAt', required: false, label: 'Certificate Exp. Date', display_label: 'Certificate Exp. Date'},
  WORKERS_COMP_CERT_NUMBER: {api_name: 'number', required: false, label: 'Workers Comp Number', display_label: 'Workers Comp Number'},
  WORKERS_COMP_TYPE: {api_name: 'type', required: false, label: 'Type', display_label: 'Type'},

  PAYMENT_METHOD: {api_name: 'paymentMethod', required: true, label: 'Method Of Payment', display_label: 'Method Of Payment'},
  EMAIL_PREFERENCES: {api_name: 'emailPreferences', required: false, label: 'Email Preferences', display_label: 'Email Preferences'},
  PAYMENT_FEE: {api_name: 'paymentFee', required: false, label: 'Payment Fee', display_label: 'Payment Fee'},

  ADMIN_INFO: {api_name: 'administrator', required: true, label: 'Administrator Name', display_label: 'Administrator Name'},
  ADMIN_NAME_FIRST: {api_name: 'firstName', required: true, label: 'First Name', display_label: 'First Name'},
  ADMIN_NAME_LAST: {api_name: 'lastName', required: true, label: 'Last Name', display_label: 'Last Name'},
  ADMIN_EMAIL: {api_name: 'email', required: false, label: 'Administrator Email', display_label: 'Administrator Email'},
  ADMIN_PHONE: {api_name: 'phoneNumber', required: false, label: 'Administrator Phone', display_label: 'Administrator Phone'},

  //ipermit Fields
  LOCATION: {api_name: 'location', required: true, label: 'Location', display_label: 'Location'},
  ALLOWED_PERMITS: {api_name: 'allowedPermits', required: true, label: 'Allowed Permits', display_label: 'Allowed Permits'},
  ALLOWED_TESTS: {api_name: 'allowedTests', required: true, label: 'Allowed Tests', display_label: 'Allowed Tests'},
  DISCOUNT: {api_name: 'discount', required: false, label: 'Discount', display_label: 'Discount'},
  QB_ID: {api_name: 'quickBooksId', required: false, label: 'QuickBooks id', display_label: 'QuickBooks id'},
  QB_EMAIL: {api_name: 'qbEmail', required: false, label: 'QuickBooks email', display_label: 'QuickBooks email'},
  ALL_IN_ONE: {api_name: 'allInOne', required: false, label: 'All in one', display_label: 'All in one'},
  ACCOUNTING_CONTACT: {api_name: 'accountingContact', required: false, label: 'Accounting Contact', display_label: 'Accounting Contact'},
  CLASSIFICATION: {api_name: 'classification', required: false, label: 'Classification', display_label: 'Classification'},
  PAYMENT_AUTH_FORM: {api_name: 'paymentAuthForm', required: false, label: 'Payment Auth Form', display_label: 'Payment Auth Form'},
  PAYMENT_AUTH_FORM_FILE: {api_name: 'fileUploadId', required: false, label: 'Payment Auth Form', display_label: 'Payment Auth Form'},
  PRICE_LIST: {api_name: 'priceList', required: false, label: 'Price List', display_label: 'Price list'},
  CITY_INSPECTION: {api_name: 'inspection', required: false, label: 'City Inspection', display_label: 'City Inspection'},
  CHARGE_INITIAL_HERS: {api_name: 'chargeInitialHers', required: false, label: 'Charge HERS Admin Fee', display_label: 'Charge HERS Admin Fee'},

  SERVICE_TITAN: {api_name: 'serviceTitan', required: false, label: 'Service Titan', display_label: 'Service Titan'},
  TENANT_ID: {api_name: 'tenantId', required: false, label: 'Tenant Id', display_label: 'Tenant Id'},
  TENANT_CLIENT_ID: {api_name: 'tenantClientId', required: false, label: 'Client Id', display_label: 'Client Id'},
  TENANT_CLIENT_SECRET: {api_name: 'tenantClientSecret', required: false, label: 'Client Secret', display_label: 'Client Secret'},
  ACTIVE_TENANT: {api_name: 'activeTenant', required: false, label:'Active?', display_label:'Active?'}, 
  IS_PROD: {api_name: 'isProduction', required: false, label:'Production?', display_label:'Production?'}, 
  USER_INTEGRATION: {api_name: 'userIntegration', required: false, label:'Integrated Security', display_label:'Integrated Security?'},  
  TENANT_EMP_LIST: {api_name: 'employees', required: false, label: 'Users', display_label: 'Users'},
  DEFAULT_CREATE_JOB_EMAIL: {api_name: 'defaultCreateJobEmail', required: false, label: 'Default Create Job Email', display_label: 'Default Create Job Email'},
};

const COMPANY_OPTIONS = {
  [COMPANY_FIELDS.LOCATION.api_name]: {
    NORTH: {display: 'north', value: 'north', showOrder: 0, def: false},
    SOUTH: {display: 'south', value: 'south', showOrder: 1, def: false},
    NEVADA: {display: 'nevada', value: 'nevada', showOrder: 2, def: false},
    ARIZONA: {display: 'arizona', value: 'arizona', showOrder: 3, def: false},
    CENTRAL: {display: 'central', value: 'central', showOrder: 4, def: false},
  },
  // [COMPANY_FIELDS.EMAIL_PREFERENCES.api_name]: {
  //   EVERYONE: {display: 'everyone', value: 'everyone',  showOrder: 0, def: false},
  //   CREATOR: {display: 'creator', value: 'creator',  showOrder: 1, def: false},
  // }
};


export {COMPANY_FIELDS, COMPANY_OPTIONS};
