import {Box, Card, List, ListItem} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import * as allConstants from '../../../../../constants/allConstants';
import {useJob} from '../../../../../contextProviders/jobContext';
import {useEditRecord} from '../../../../../hooks/useEditRecord';
import {useSubform} from '../../../../../hooks/useSubform';
import {AddRowButton, variants} from '../../../../../pieces/addDeleteEquipmentRowButtons';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import {InspectionRequirementsForContractor} from '../../../../../pieces/inspectionRequirementsForContractor';
import {NoDataStringWrapper} from '../../../../../pieces/noDataStringWrapper';
import Spacer from '../../../../../pieces/spacer';
import {CustomTable} from '../../../../../pieces/table';
import LadderIcon from '../../../../../static/images/ladder.png';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import ApiUtils from '../../../../../utils/apiUtils';
import Utils from '../../../../../utils/utils';
import {WrappedHomeOwnerInfoRows} from '../../../components/homeownerInfoBlock';
import {SMS_TEMPLATE_NAMES, useGetSms} from '../../../../../hooks/useGetSms';
import {JobActionButtonsContained} from '../../../../../pieces/jobActionButtons';
import {useAutocompleteOptions} from '../../../../../hooks/useAutompleteOptions';
import {NewPermissionGate} from '../../../../../permissions/permissionGate';
import {
  ELEMENTS_INSPECTIONS,
  inspectionRules
} from '../../../../../permissions/ipermitAccessControl/inspectionsAccessControl';
import {useGotIt} from '../../../../../hooks/useGotIt';


const InspectionCallLogs = (props)=> {
  const classes = {...globalUseClasses()};
  const gotItPopup = useGotIt();
  const user = useSelector(state=>state.user);
  const {job, setJob} = useJob();
  const {INSPECTION_FIELDS,
    JOB_FIELDS, CALL_LOGS_FIELDS,
    CALL_RESULTS_CITY,
    CALL_RESULTS_HOME_OWNER,
    CALL_TO_OPTIONS,
    INSPECTION_TYPES,
    SCHEDULING_FIELDS,
  } = allConstants;

  const options = {
    cityOptions: useAutocompleteOptions({field: 'cityOptions'}).options,
  };
  const [currentCalls, setCurrentCalls] = useState(job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[CALL_LOGS_FIELDS.CALL_LOG.api_name] || []);
  const [addingCallTo, setAddingCallTo] = useState([]);

  useEffect(()=> {
    setCurrentCalls(job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[CALL_LOGS_FIELDS.CALL_LOG.api_name] || []);
  }, [job]);


  const collectionName = useMemo(()=> CALL_LOGS_FIELDS.CALL_LOG.api_name, []);

  const validationSchema = useMemo(()=>Yup.object().shape({
    [collectionName]: Yup.array().of(Yup.object().shape({
      [CALL_LOGS_FIELDS.CALL_RESULT.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
      [CALL_LOGS_FIELDS.TYPE.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
    }))
  }), []);

  const collectionRow = useMemo(()=>{return {
    [CALL_LOGS_FIELDS.DATE.api_name]: '',
    [CALL_LOGS_FIELDS.TYPE.api_name]:null,
    [CALL_LOGS_FIELDS.CALL_RESULT.api_name]:null,
    [CALL_LOGS_FIELDS.LOGGED_BY.api_name]: null,
    [CALL_LOGS_FIELDS.NOTE.api_name]:''
  };}, []);

  const {getValue, commonFieldProps, fields, formMethods,
    addRowAction, getDeleteRowButton, replace} = useSubform({
    collectionName: collectionName,
    validationSchema: validationSchema,
    subformRow: collectionRow,
    currentResults: [],
    addRowText: 'Add call'
  });

  const addCall = (callTo)=> {
    const addCallTo = [...addingCallTo];
    addCallTo.push(callTo);
    setAddingCallTo(addCallTo);
    addRowAction();
  };

  const  {edit, EditButton, cancelEdit} = useEditRecord();
  const textHomeownerForAvailability = useGetSms(
    {
      templateName: SMS_TEMPLATE_NAMES.ASK_HOMEOWNER_AVAILABILITY,
      data: {
        jobNumber: job?.[JOB_FIELDS.JOB_NUMBER.api_name],
        company: job?.company?.[allConstants.COMPANY_FIELDS.NAME.api_name]?.toUpperCase(),
        address: Utils.getAddressString(job?.[JOB_FIELDS.GENERAL.ADDRESS.api_name]),

      }
    }
  );

  const userAllowedToEditInspection = useMemo(()=> {
    return NewPermissionGate({
      user: user,
      elementPermissions: inspectionRules[ELEMENTS_INSPECTIONS.manageInspection],
    });
  }, [user]);


  useEffect(()=> {
    if(edit && !userAllowedToEditInspection) {
      gotItPopup.setShow(allConstants.ERROR_MSG.NO_PERMISSION('edit inspection'));
      cancelEdit();
      return;
    }
    replace([]);
    editInspectionForm.reset(job?.[INSPECTION_FIELDS.INSPECTION.api_name]);

  },[edit]);

  useEffect(()=> {
    if(!edit) {
      const currentCallsCount = currentCalls?.length || 0;
      if(addingCallTo.length > currentCallsCount) {
        if (currentCallsCount > 0) {
          const calls = [...addingCallTo];
          calls.splice(currentCallsCount - 1, addingCallTo.length - currentCallsCount);
          setAddingCallTo(calls);
        } else {
          setAddingCallTo([]);
        }
      }
    }
  }, [edit]);

  const inspectionFormCommonProps = {
    fullWidth: true,
    maxWidth: '100%',
    isEditable: true,
    renderLabel: true,
    viewMode: !edit
  };

  if(Utils.isDevEnv()) {
    window.callLogs = formMethods;
  }

  const getCurrentCallType = (index)=> {
    return addingCallTo[index];
  };

  const createNameString = (name)=> {
    if(!name) return '';
    return `${name[allConstants.USER_FIELDS.FIRST_NAME.api_name]} ${name[allConstants.USER_FIELDS.LAST_NAME.api_name]}`;
  };

  const handleSubmitResponse = (result, message, key)=> {
    const toastOptions = {
      toastId: 'inspection_response',
      autoClose: 1000,
    };
    setAddingCallTo([]);


    if(result.status === 'success') {
      const calls = result?.data?.[CALL_LOGS_FIELDS.CALL_LOG.api_name];

      try{
        const returnedInspectionData = result.data?.[INSPECTION_FIELDS.INSPECTION.api_name];
        let updatedInspection = {...job[INSPECTION_FIELDS.INSPECTION.api_name]};
        if(returnedInspectionData) {
          updatedInspection = {...updatedInspection, ...returnedInspectionData};
        }
        if(calls){
          updatedInspection[CALL_LOGS_FIELDS.CALL_LOG.api_name] = calls;
        }

        setJob({...job, ...{[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]: updatedInspection}});
      } catch(e) {
        console.log('Error on handling update inspection and call logs', e);
      }

      // formMethods.reset(result?.data?.[INSPECTION_FIELDS.INSPECTION.api_name]);
      cancelEdit();
      toast.success(message ? message : 'Inspection updated', toastOptions);
    } else{
      toast.error(`Inspection update error: ${result?.message}`, toastOptions);
    }
  };

  const gridTemplateColumns = props.isContractor ? '100px 1fr 1.5fr 1.5fr 3fr':'170px 200px 1fr 40px';
  const gap = '16px';

  const getCallOptions = (index)=> {
    const callTo = addingCallTo[index];
    if(callTo === CALL_TO_OPTIONS.HOME_OWNER.value) {
      return Utils.getOptions(CALL_RESULTS_HOME_OWNER);
    } else {
      return Utils.getOptions(CALL_RESULTS_CITY);
    }
  };

  const editInspectionForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  });


  if(Utils.isDevEnv()) {
    window.editInspection = editInspectionForm;
  }

  const getInspectionValueFor = (field, defaultValue)=> {
    const inspection = job?.[INSPECTION_FIELDS.INSPECTION.api_name];
    return inspection?.[field.api_name] || defaultValue;
  };

  const resolveAllPromises = (promises)=> {
    const errors = [];
    Promise.all(promises).then((results)=> {
      let updateData = {};
      results.forEach((result)=> {
        if(result.status === 'success') {
          updateData = {...updateData, ...result.data};
        }
        else {
          errors.push(result.message);
        }
      });
      if(Object.keys(updateData).length > 0) {
        handleSubmitResponse({status: 'success', data: updateData}, 'Inspection updated');
      } else {
        handleSubmitResponse({status: 'error', message: errors.join(', ')}, errors.join(', '));
      }

    });
  };

  const onSubmit = async (data)=> {
    if(props.isContractor) return;
    let promises = [];
    const jobId = job?.[JOB_FIELDS.ID.api_name];
    const addedRows = data?.[collectionName];

    const inspectionData = editInspectionForm.getValues();
    inspectionData.id = jobId;

    // Thanh 02/04/24
    //console.log('call logs Panel');
    //console.log('inspection data', inspectionData);
    //console.log('data');
    //console.log(data);


    promises.push(ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/edit-inspection', JSON.stringify(inspectionData)).then(
      (result)=> {return result;}
    ));

    if(addedRows.length > 0) {

      //console.log('new calls');
      //console.log(addedRows);
      
      
      promises.push(ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/add-inspection-calls', JSON.stringify({
        id: jobId,
        calls: addedRows,
      })).then(
        (result)=> {return result;}
      ));
    }
    resolveAllPromises(promises);
  };

  const getCallFieldValue = (fieldName, index, returnValue)=> {
    if(props.isContractor){
      return job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[collectionName]?.[index]?.[fieldName];
    }
    return returnValue ? returnValue : getValue(fieldName, index);
  };

  const onRowDelete = (index)=> {
    const calls = [...addingCallTo];
    calls.splice(index, 1);
    setAddingCallTo(calls);
  };

  const toggleInspectionTo = ()=> {
    return getInspectionValueFor(allConstants.INSPECTION_FIELDS.TYPE) === allConstants.INSPECTION_TYPES.ROUGH.value
      ? allConstants.INSPECTION_TYPES.FINAL.value : allConstants.INSPECTION_TYPES.ROUGH.value;
  };

  const changeInspectionType = async ()=> {
    if(!userAllowedToEditInspection){
      gotItPopup.setShow(allConstants.ERROR_MSG.NO_PERMISSION('edit inspection type'));
      return;
    }
    const inspectionData = {
      id: job?.[JOB_FIELDS.ID.api_name],
    };
    inspectionData[allConstants.INSPECTION_FIELDS.TYPE.api_name] = toggleInspectionTo();
    let promises = [];
    promises.push(ApiUtils.makeApiCall('POST', 'ipermit/jobs/city-inspection/edit-inspection', JSON.stringify(inspectionData)).then(
      (result)=> {return result;}
    ));
    resolveAllPromises(promises);

  };

  const createCallLogTableData = ()=> {
    const pastCalls = [...currentCalls].reverse();
    const header = [
      CALL_LOGS_FIELDS.DATE.label,
      CALL_LOGS_FIELDS.TYPE.label,
      CALL_LOGS_FIELDS.RESULT.label,
      CALL_LOGS_FIELDS.LOGGED_BY.label,
      CALL_LOGS_FIELDS.NOTE.label
    ];
    const data = pastCalls.map((row)=> {
      return [
        Utils.formatIsoDateString(row[CALL_LOGS_FIELDS.DATE.api_name]),
        row[CALL_LOGS_FIELDS.TYPE.api_name],
        row[CALL_LOGS_FIELDS.CALL_RESULT.api_name],
        createNameString(row[CALL_LOGS_FIELDS.LOGGED_BY.api_name]),
        row[CALL_LOGS_FIELDS.NOTE.api_name]
      ];
    });
    const columnWidths = [190, 150, 200, 230, null];
    return {
      tableHeader: header,
      tableData: data,
      columnWidths: columnWidths
    };
  };

  const deleteCallRow = (index)=> {
    console.log('will delete row', index);
  };

  const editCallRowAction = (index)=> {
    console.log('will edit row', index);
  };

  const canChangeInspectionType = ()=> {
    //don't allow to change inspection type when inspection is scheduled
    const currentStatus = job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[INSPECTION_FIELDS.INSPECTION_STATUS.api_name];
    return currentStatus !== allConstants.INSPECTION_STATUSES.SCHEDULED.value;
  };

  const getRaterName = ()=> {
    const inspectionData = job?.[INSPECTION_FIELDS.INSPECTION.api_name] || {};
    return inspectionData?.rater?.name || '';
  };

  return(
    <>
      {gotItPopup.PopUp()}
      {props.isContractor && <>
        <InspectionRequirementsForContractor job={job} />
        <Spacer y={1} />
      </>
      }
      {!props.isContractor && <Box>
        <Box position={'absolute'} right={0} zIndex={100} display={'flex'} flexDirection={'column'} rowGap={'20px'}>
          {EditButton}
          <JobActionButtonsContained action={changeInspectionType}
            disabled={edit || !canChangeInspectionType()}
            text={`Set ${toggleInspectionTo()}`} />

          {textHomeownerForAvailability.ButtonGetSms}
          {textHomeownerForAvailability.Popup}

        </Box>


        <WrappedHomeOwnerInfoRows/>

        <Spacer y={2} />
        <Card className={classes.card_block} sx={{
          marginRight: '150px'
        }}>
          <Box p={2}>
            <FormProvider {...editInspectionForm}>
              <form onSubmit={editInspectionForm.handleSubmit(()=>{return;})} method={'POST'}>

                <Box display={'grid'} sx={{
                  gridTemplateColumns: {sx:'1fr', sm:'2fr 1fr'},
                  columnGap: {sm: '16px', md: '24px'},
                  '& .MuiInputBase-root textarea': {
                    width: '100%',
                    maxWidth: '500px'
                  }

                }}>
                  {/* <Box display={'flex'} flexDirection={'column'} sx={{
                    '& .MuiInputBase-root textarea': {
                      maxWidth: 'unset',
                    }
                  }}>
                    <CustomFormBlock {...inspectionFormCommonProps}
                      field={INSPECTION_FIELDS.HO_AVAILABILITY}
                      multiline={true}
                      rowCount={2}
                      fullWidth
                      defaultValue={getInspectionValueFor(INSPECTION_FIELDS.HO_AVAILABILITY)}
                    />

                    <CustomFormBlock {...inspectionFormCommonProps}
                      field={INSPECTION_FIELDS.CITY_AVAILABILITY}
                      multiline={true}
                      rowCount={2}
                      fullWidth
                      defaultValue={getInspectionValueFor(INSPECTION_FIELDS.CITY_AVAILABILITY)}
                    />

                    <CustomFormBlock {...inspectionFormCommonProps} key={getInspectionValueFor(SCHEDULING_FIELDS.SCHEDULER_NOTES)}
                      field={SCHEDULING_FIELDS.SCHEDULER_NOTES}
                      multiline={true}
                      rowCount={2}
                      fullWidth
                      defaultValue={getInspectionValueFor(SCHEDULING_FIELDS.SCHEDULER_NOTES)}
                    />
                  </Box> */}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} flexDirection={'row'} >
                      <CustomFormBlock key={getRaterName()}
                        {...inspectionFormCommonProps}
                        field={SCHEDULING_FIELDS.RATER_NAME}
                        isEditable={false}
                        fullWidth
                        defaultValue={getRaterName()}
                      />
                      <CustomFormBlock
                        {...inspectionFormCommonProps}
                        field={SCHEDULING_FIELDS.RATER_ID}
                        hide={true}
                      />
                      <Spacer x={2} />
                      <CustomFormBlock {...inspectionFormCommonProps} key={getInspectionValueFor(INSPECTION_FIELDS.CONFIRMATION_NUMBER)}
                        field = {INSPECTION_FIELDS.CONFIRMATION_NUMBER}
                        defaultValue={getInspectionValueFor(INSPECTION_FIELDS.CONFIRMATION_NUMBER)}
                      />
                      <Spacer x={2} />
                      <CustomFormBlock {...inspectionFormCommonProps} key={getInspectionValueFor(SCHEDULING_FIELDS.COLLECT_AMOUNT)}
                        field = {SCHEDULING_FIELDS.COLLECT_AMOUNT}
                        inputType={inputTypes.currency}
                        defaultValue={getInspectionValueFor(SCHEDULING_FIELDS.COLLECT_AMOUNT)}
                      />                      
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                      <CustomFormBlock {...inspectionFormCommonProps}
                        inputType={inputTypes.singleSelect}
                        options={Utils.getOptions(options.cityOptions?.cityInspectionRequirements)}
                        allowNone={true}
                        field={INSPECTION_FIELDS.REQUIREMENTS}
                        defaultValue={getInspectionValueFor(INSPECTION_FIELDS.REQUIREMENTS, options.cityOptions?.cityInspectionRequirements?.STANDARD?.value)}
                      />
                      <Spacer x={2} />
                      <Box display={'flex'}>
                        <CustomFormBlock {...inspectionFormCommonProps} key={getInspectionValueFor(INSPECTION_FIELDS.TYPE)}
                          field = {INSPECTION_FIELDS.TYPE}
                          defaultValue={getInspectionValueFor(INSPECTION_FIELDS.TYPE)}
                          inputType={inputTypes.singleSelect}
                          isEditable={canChangeInspectionType()}
                          options={Utils.getOptions(INSPECTION_TYPES)}
                        />
                        <Spacer x={1} />
                        <Box sx={{
                          display: 'flex',
                        }}>
                          <Box display={'flex'} sx={{
                            // ' .MuiFormControlLabel-root':{
                            //   width: '40px' //prevent style issie in Firefox
                            // },
                            '& img': {
                              height: '100%',
                              transform: {xs: 'scale(0.5) translateX(-46px) translateY(-14px)', xl: 'scale(0.7) translateX(-24px) translateY(-10px)'},
                            }
                          }}>
                            <CustomFormBlock {...inspectionFormCommonProps}
                              inputType={inputTypes.checkbox}
                              renderLabel={false}
                              field={INSPECTION_FIELDS.LADDER}
                              defaultValue={getInspectionValueFor(INSPECTION_FIELDS.LADDER)}
                            />
                            <img src={LadderIcon} alt={'need ladder'}/>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            </FormProvider>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
                <List>
                  {fields.map((item, index) => (
                    <ListItem key={item.id+index} sx={{
                      paddingX: 0,
                      display: 'grid',
                      gridTemplateColumns: gridTemplateColumns,
                      gap: gap,
                      alignItems: 'flex-start',
                      '& .MuiInputBase-inputMultiline': {
                        width: '100% !important',
                      },
                    }}>
                      <CustomFormBlock {...commonFieldProps(index)}
                        showAsStatic={props.isContractor}
                        viewMode={!edit}
                        isEditable={false}
                        field={CALL_LOGS_FIELDS.TYPE}
                        defaultValue={getCallFieldValue(CALL_LOGS_FIELDS.TYPE.api_name, index, getCurrentCallType(index))}
                      />
                      <CustomFormBlock {...commonFieldProps(index)}
                        showAsStatic={props.isContractor}
                        viewMode={!edit}
                        isEditable={true}
                        inputType={inputTypes.singleSelect}
                        options={getCallOptions(index)}
                        defaultValue={getCallFieldValue(CALL_LOGS_FIELDS.CALL_RESULT.api_name, index)}
                        field={CALL_LOGS_FIELDS.CALL_RESULT}
                      />

                      <CustomFormBlock {...commonFieldProps(index)}
                        showAsStatic={props.isContractor}
                        multiline={true}
                        rowCount={1}
                        field={CALL_LOGS_FIELDS.NOTE}
                        defaultValue={getCallFieldValue(CALL_LOGS_FIELDS.NOTE.api_name, index)}
                      />
                      {edit && !props.isContractor && getDeleteRowButton(index, ()=>onRowDelete(index))}
                    </ListItem>
                  ))}
                </List>
                {edit && !props.isContractor && <Box display={'flex'}>
                  <AddRowButton eqName={collectionName} variant={variants.textWithIcon}
                    addAction={()=>addCall(CALL_TO_OPTIONS.CITY.value)} text={'Add City call'}/>
                  <Spacer x={2}/>
                  <AddRowButton eqName={collectionName} variant={variants.textWithIcon}
                    addAction={()=>addCall(CALL_TO_OPTIONS.HOME_OWNER.value)} text={'Add Homeowner call'}/>
                </Box>}
                {edit && !props.isContractor &&<FormSubmitButtons cancelAction={cancelEdit} submitText={'Update'}/>}
              </form>
            </FormProvider>
          </Box>

        </Card>
      </Box>}
      <Spacer y={2}/>
      <Box>
        {currentCalls && currentCalls.length !==0 && <CustomTable tableName={'previous calls'}
          {...createCallLogTableData()}
          isEditable={user.role === allConstants.ROLES.SYSTEM_ADMIN}
          deleteRowAction={deleteCallRow}
          editRowAction={editCallRowAction}
        />}
        {!currentCalls || currentCalls.length ===0 &&
        <NoDataStringWrapper text={'No Calls logged'}/>
        }
      </Box>
    </>
  );
};

InspectionCallLogs.propTypes = {
  isContractor: PropTypes.bool,
};

InspectionCallLogs.defaultProps = {
  isContractor: false,
};

export default InspectionCallLogs;
