import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../constants/allConstants';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {companyViewModes} from '../companyViewModes';
import {useDispatch, useSelector} from 'react-redux';
import {CompanyFinancial} from './companyFinancial';
import {Box, Grid, Typography} from '@mui/material';
import FormSubmitButtons from '../../../pieces/formSubmitButtons';
import {COLORS} from '../../../styles/styleConstants';
import {ERROR_MSG, PRODUCT_FIELDS} from '../../../constants/allConstants';
import {RecordHeader} from '../../../pieces/recordHeader';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {validatePrices} from '../companyUtils';
import Utils from '../../../utils/utils';
import actions from '../../../redux/actions';
import {unwrapRequestResult} from '../../../utils/unwrapRequestResult';
import StatusRenderer from '../../../pieces/grids/statusRenderer';
import Spacer from '../../../pieces/spacer';
import * as Yup from 'yup';
import {requiredRowFieldsValidator} from '../../../utils/validators/subformValidator';
import validator from '../../../utils/validators/validator';
import base_url from '../../../baseUrls';
import ApiUtils from '../../../utils/apiUtils';
import axios from 'axios';
import Loader from '../../misc/loader';



export const EditFinancialForm = (props) => {
  const {COMPANY_FIELDS} = allConstants;
  const user = useSelector(state=> state.user);
  const dispatch = useDispatch();
  const recordId = props.id;
  const [isFetching, setFetching] = useState(false);
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    if(!recordId) return;
    const fetch = async () => {
      let url = `${base_url.api}/companies/${recordId}`;
      const authToken = await ApiUtils.getAccessToken();
      const companyResponse= await axios.get(url, {headers: {Authorization: authToken}});
      return companyResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {
        setCompanyData(data.data.data?.company);
        setFetching(false)
        ;
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
      });
  }, [props.id]);

  Yup.addMethod(Yup.string, 'validateEmailIfNotEmpty', function (errorMessage) {
    return this.test('email', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidEmail(value, true) ||
        createError({path, message: errorMessage})
      );
    });
  });


  const validationSchema = Yup.object().shape({
    [COMPANY_FIELDS.QB_EMAIL.api_name]: Yup.string().validateEmailIfNotEmpty(ERROR_MSG.EMAIL_INVALID).nullable(),
    [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: Yup.string()
      .required('Method of payment can\'t be empty').nullable(),
    [COMPANY_FIELDS.PRICE_LIST.api_name]: Yup.array().of((() => {
      const {shape, edges} = requiredRowFieldsValidator.validateIfAnyInRowIsNotEmpty(
        [PRODUCT_FIELDS.NAME.api_name, PRODUCT_FIELDS.PRICE.api_name, PRODUCT_FIELDS.CODE.api_name],
        [{field: PRODUCT_FIELDS.NAME}, {field: PRODUCT_FIELDS.PRICE}, {field: PRODUCT_FIELDS.CODE}]
      );
      return Yup.object().shape(shape, edges);
    })())
  });

  const getDefaultValues = () => {
    return {
      [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: companyData?.[COMPANY_FIELDS.PAYMENT_METHOD.api_name],
      [COMPANY_FIELDS.PAYMENT_FEE.api_name]: companyData?.[COMPANY_FIELDS.PAYMENT_FEE.api_name],
      [COMPANY_FIELDS.CLASSIFICATION.api_name]: companyData?.[COMPANY_FIELDS.CLASSIFICATION.api_name],
      [COMPANY_FIELDS.QB_EMAIL.api_name]: companyData?.[COMPANY_FIELDS.QB_EMAIL.api_name],
      [COMPANY_FIELDS.QB_ID.api_name]: companyData?.[COMPANY_FIELDS.QB_ID.api_name],
      [COMPANY_FIELDS.ACCOUNTING_CONTACT.api_name]: companyData?.[COMPANY_FIELDS.ACCOUNTING_CONTACT.api_name],
      [COMPANY_FIELDS.CITY_INSPECTION.api_name]: companyData?.[COMPANY_FIELDS.CITY_INSPECTION.api_name],
      [COMPANY_FIELDS.AUTH_LETTER.api_name]: companyData?.[COMPANY_FIELDS.AUTH_LETTER.api_name],
      [COMPANY_FIELDS.PRICE_LIST.api_name]: companyData?.[COMPANY_FIELDS.PRICE_LIST.api_name] || [],
    };
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    criteriaMode:'all',
    defaultValues: getDefaultValues()
  };

  const inputDefaultProps = {
    viewMode: false,
    isEditable: true,
    renderLabel: true,
    nested: false,
    parent: null,
  };

  const tabProps = {
    isSelfRegistering: false,
    isIpermitInvite: false,
    isCompleteonboarding: false,
    companyData: companyData,
    user: user,
    companyViewMode: companyViewModes.general,
    dontUploadImmediately: false,
    options: [],
  };

  const formMethods = useForm(formOptions);
  useEffect(() => {
    formMethods.reset(getDefaultValues());
  }, [companyData]);

  const getValue = (field) => {
    return companyData?.[field.api_name];
  };

  const headerHeight = '75px';

  const onSubmit = async (data) => {

    // Thanh 01/04/24
    //console.log('data to sumbit');
    //console.log(data);

    const sanitizedData = Utils.sanitizeFileUploadFields(data);
    let invalidPrices = validatePrices(data[COMPANY_FIELDS.PRICE_LIST.api_name]);
    if(invalidPrices.length !== 0) {
      invalidPrices.forEach((el, index)=> {
        formMethods.setError(`${COMPANY_FIELDS.PRICE_LIST.api_name}.[${el}].price`, {type: 'custom', message: ERROR_MSG.PRICE_SHOULD_BE_A_NUMBER});
      });
      return;
    }

    let responseStatus;
    let successMessage;
    const payload = {
      ...sanitizedData, ...{
        companyId: companyData[allConstants.COMPANY_FIELDS.ID.api_name]}
    };

    // Thanh 01/04/24
    //console.log('payload to edit company');
    console.log(payload);

    successMessage = 'Company successfully updated.';
    responseStatus = await dispatch(actions.editCompany(payload));
    if(responseStatus) {
      const unwrapped = unwrapRequestResult({
        showSuccess: true,
        result: responseStatus,
        successMessage: successMessage,
        id: 'company_update',
      });
      if(unwrapped && Utils.objectHasProperty(unwrapped, 'company') && unwrapped.company) {
        //merge with current company object to make sure we display company admin name - it is not returned in edit company response.
        //setCompany({...company, ...unwrapped.company});
      }
      if(unwrapped?.status !== 'error') {
        //toggleViewMode(true);
      }
      if(responseStatus?.meta?.requestStatus === 'fulfilled') {
        //handleSuccess();
      }
    }
  };

  if(recordId && !companyData) return null;

  return(
    <Grid container sx={{
      minHeight: '400px'
    }}>
      {/*<Loader useOwnWrapper/>*/}
      {isFetching && <Loader timeout={5}/>}
      {recordId && !isFetching &&
        <>
          <Grid item xs={12}>
            <Box display={'flex'} flexDirection={'row'} id={'headercontainer'} justifyContent={'center'} sx={{
              width: '100%',
            }}>
              <Box display={'flex'} flexDirection={'row'} width={'100%'} height={'75px'}>
                <Box display={'flex'}
                  width={'calc(100% - 110px)'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  gap={0.5}
                >
                  <Box display={'flex'} flexDirection={'row'} textTransform={'uppercase'}>
                    <RecordHeader
                      text={getValue(COMPANY_FIELDS.NAME)}
                      headerHeight={headerHeight}
                    />
                    <Spacer x={2}/>
                    <StatusRenderer value={getValue(COMPANY_FIELDS.STATUS)}/>
                  </Box>

                  {getValue(COMPANY_FIELDS.EMAIL) &&
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <EmailOutlinedIcon sx={{color: COLORS.globalBlue, marginRight: '8px'}}/>
                <Typography component={'span'} sx={{
                  fontStyle: 'italic'
                }}>{getValue(COMPANY_FIELDS.EMAIL)}</Typography>
              </Box>}

                </Box>
              </Box>
            </Box>
          </Grid>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Grid item xs={12} sx={{
                textTransform: 'uppercase',
              }}>
                <CompanyFinancial inputDefaultProps={inputDefaultProps} tabProps={tabProps}/>
              </Grid>
              <Grid item xs={12}>
                <Box display={'flex'}
                  justifyContent={'center'}
                  alignItems={'flex-end'}
                  height={'100%'}
                  minHeight={'32px'}
                >
                  <FormSubmitButtons
                    cancelText={'Cancel'}
                    submitText={'Update'}
                    cancelAction={props.handleClose}
                  />


                </Box>
              </Grid>
            </form>
          </FormProvider>
        </>}

    </Grid>
  );
};

EditFinancialForm.propTypes = {
  id: PropTypes.string,
  handleClose: PropTypes.func,
};

EditFinancialForm.defaultProps = {
  id: null,
  handleClose: () => {},
};
