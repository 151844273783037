import {EQUIPMENT} from '../equipment';

const JOB_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  IS_DRAFT: {api_name: 'isDraft', required: false, label:'', display_label:''},
  SERVICE_TITAN_ID: {api_name: 'serviceTitanId', required: false, label: 'Srv Titan Id'},
  IS_SERVICE_TITAN: {api_name: 'isServiceTitan', required: false, label: 'Srv Titan?', display_label: 'Srv Titan?'},
  ZOHO_ID: {api_name: 'zohoId', required: false, label: 'zohoId', display_label: 'zohoId'},
  SERVICE_TITAN_ID: {api_name: 'serviceTitanId', required: false, label: '', display_label: ''},
  JOB_NUMBER: {api_name: 'jobNumber',crm_name: 'Name', required: false, label: 'Job#', display_label: 'Job#'},
  JOB_TYPE: {api_name: 'jobType', crm_name: 'Job_Type', required: true, label: 'Job Type', display_label: 'Job Type'},
  PERMIT_STATUS: {api_name: 'permitStatus', required: false, label: 'Permit Status', display_label: 'Permit Status'},
  PERMIT_STATUS_CHANGE_DATE: {api_name: 'permitStatusChangeDate', required: false, label: 'Change Date', display_label: 'Change Date'},
  HERS_STATUS:{api_name: 'hersStatus', required: false, label: 'HERS Status', display_label: 'HERS Status'},
  HERS_STATUS_CHANGE_DATE: {api_name: 'hersStatusChangeDate', required: false, label: 'Change Date', display_label: 'Change Date'},
  HERS_SCHEDULED_DATE: {api_name: 'scheduledDate', required: false, label: 'Scheduled Date', display_label: 'Scheduled Date'},
  MAILING_STATUS:{api_name: 'mailingStatus', required: false, label: 'Mailing Status', display_label: 'Mailing Status'},
  MAILING_STATUS_CHANGE_DATE: {api_name: 'mailingStatusChangeDate', required: false, label: 'Change Date', display_label: 'Change Date'},
  CREATE_TIME: {api_name: 'createdAt', crm_name: 'Created_Time', required: false, label: 'Created', display_label: 'Created'},
  UPDATE_TIME: {api_name: 'updatedAt', crm_name: 'Updated_Time', required: false, label: 'Updated', display_label: 'Updated'},
  ORDERED_BY: {api_name: 'orderedByUser', crm_name: 'Ordered_By', required: false, label: 'Ordered By', display_label: 'Ordered By'},
  DUPLICATE_OF: {api_name: 'duplicateOf', required: false, label: 'Duplicate Of', display_label: 'Duplicate Of'},
  TIMEZONE: {api_name: 'timezone', required: false, label: '', display_label: ''},
  GENERAL: {
    HOMEOWNER: {api_name: 'homeOwner', required: false, label: '', display_label: ''},
    HO_NAME: {
      api_name: 'firstName',
      crm_name: 'Homeowner_Name',
      required: true,
      label: 'Homeowner\'s Name',
      display_label: 'Homeowner Name'
    },
    FIRST_NAME: {api_name: 'firstName', required: false, label: 'First Name'},
    LAST_NAME: {api_name: 'lastName', required: false, label: 'Last Name'},
    HO_PHONE: {
      api_name: 'phoneNumber',
      crm_name: 'Homeowner_Phone',
      required: true,
      label: 'Homeowner\'s Phone',
      display_label: 'Homeowner Phone'
    },
    ALT_HO_PHONE: {
      api_name: 'altPhoneNumber',
      crm_name: 'Alt_Homeowner_Phone',
      required: false,
      label: 'Homeowner\'s Alt Phone',
      display_label: 'Homeowner Alt Phone'
    },
    PHONE_NUMBER: {api_name: 'phoneNumber', required: false, label: 'Phone No.', display_label: 'Phone No'},
    ALT_PHONE_NUMBER: {
      api_name: 'altPhoneNumber',
      required: false,
      label: 'Alt Phone No.',
      display_label: 'Alt Phone No.'
    },
    HO_EMAIL: {api_name: 'email', crm_name: 'Secondary_Email', required: false, label: 'Email', display_label: 'Email'},
    EMAIL: {api_name: 'email', required: true, label: 'Email', display_label: 'Email'},
    PROPERTY_TYPE: {
      api_name: 'propertyType',
      crm_name: 'Property_Type',
      required: true,
      label: 'Property Type',
      display_label: 'Property Type'
    },
    JOB_VALUE: {
      api_name: 'jobValue',
      crm_name: 'Job_Value',
      required: true,
      label: 'Job Value',
      display_label: 'Job Value'
    },
    CUSTOMER_JOB_NUMBER: {
      api_name: 'customerJobNumber',
      crm_name: 'Client_s_ID',
      required: false,
      label: 'Permit PO number',
      display_label: 'Permit PO #',
      print_label: 'Permit PO#'
    },
    SQFT: {api_name: 'sqft', crm_name: 'Sqft', required: false, label: 'SQFT', display_label: 'SQFT'},
    HERS_PO_NUMBER: {
      api_name: 'hersPONumber',
      crm_name: 'PO_Number_HERS',
      required: false,
      label: 'HERS P.O Number',
      display_label: 'HERS P.O #'
    },
    HERS_TEST_REQUIRED: {
      api_name: 'isHERSRequired',
      crm_name: 'HERS_Required',
      required: false,
      label: 'HERS Test Required',
      display_label: 'HERS Test Required'
    },
    EUC_TEST_REQUIRED: {
      api_name: 'isEUCRequired',
      crm_name: 'EUC_Required',
      required: false,
      label: 'EUC Test Required',
      display_label: 'EUC Required'
    },
    JOBSITE_ADDRESS: {
      api_name: 'address',
      crm_name: 'Jobsite_Address',
      required: true,
      label: 'Jobsite Address',
      display_label: 'Jobsite Address'
    },
    ADDRESS: {api_name: 'address', required: false, label: 'Address', display_label: 'Address'},
    JOBSITE_ADDRESS_LINE_1: {api_name: 'line1', required: true, label: 'Address', display_label: 'Address'},
    JOBSITE_ADDRESS_LINE_2: {api_name: 'line2', required: false, label: 'Suite/apt', display_label: 'Suite/apt'},
    JOBSITE_ADDRESS_CITY: {api_name: 'city', required: true, label: 'City', display_label: 'City'},
    JOBSITE_ADDRESS_STATE: {api_name: 'state', required: true, label: 'State', display_label: 'State'},
    JOBSITE_ADDRESS_ZIPCODE: {api_name: 'zipCode', required: true, label: 'Postal Code', display_label: 'Postal Code'},
    SAME_ADDRESS_JOBS: {api_name: 'sameAddressJobs', required: false, label: 'Same Address Jobs', display_label: 'Same Address Jobs'},
 
    START_DATE: {
      api_name: 'startDate',
      crm_name: 'Start_Date',
      required: false,
      label: 'Start Date',
      display_label: 'Start Date',
      print_label: 'Start'
    },
    NEED_BY_DATE: {
      api_name: 'neededByDate',
      crm_name: 'Needed_by_Date',
      required: false,
      label: 'Needed By Date',
      display_label: 'Needed By Date',
      print_label: 'Need by'
    },
    INSP_REQUEST_DATE: {
      api_name: 'inspectionRequestDate',
      crm_name: 'Inspection_Request_Date',
      required: false,
      label: 'Inspection Requested Date',
      display_label: 'Inspection Requested Date',
      print_label: 'insp'
    },
    POST_JOB_CARD: {
      api_name: 'postJobCard',
      crm_name: 'Post_Jobs_Card',
      required: false,
      label: 'Post Jobs Card (Additional Cost)',
      display_label: 'Post Jobs Card',
      print_label: 'Post JC'
    },
    ASBESTOS: {
      api_name: 'containAsbestos',
      crm_name: 'Asbestos',
      required: false,
      label: 'Contains Asbestos?',
      display_label: 'Asbestos'
    },
    NEW_CONSTRUCTION: {
      api_name: 'isNewConstruction',
      crm_name: 'new_construction',
      required: false,
      label: 'New Construction?',
      display_label: 'New Construction'
    },
    SCOPE: {api_name: 'scope', required: false, label: 'Scope', display_label: 'Scope'},
    SCOPE_OF_WORK: {api_name: 'scope', required: false, label: 'Scope of work', display_label: 'Scope of work'},
    CO_OR_NEW: {api_name: 'co_or_new', required: false, label: 'C/O or New', display_label: 'C/O or New'},
    JOB_JSON: {api_name: 'jobJson', crm_name: 'Job_JSON', required: false, label: '', display_label: ''},
    PERMIT: {api_name: 'permit', required: false, label: 'PERMIT', display_label: 'PERMIT'},
    HERS: {api_name: 'hers', required: false, label: 'HERS', display_label: 'HERS'},
    MAILING: {api_name: 'mailing', required: false, label: 'MAILING', display_label: 'MAILING'},
    EQUIPMENT_USED: {
      api_name: 'equipmentUsed',
      required: false,
      label: 'equipment used',
      display_label: 'equipment used'
    },
    ZONE: {api_name: 'zone', required: false, label: 'Zone', display_label: 'Zone'},
  },
  SHARED: {
    LOCATION:  {api_name: 'location', required: false, label: 'Location', display_label: 'Location'},
    EQUIPMENT: {api_name: 'equipment', required: false, label: 'equipment', display_label: 'equipment'},
    EQ_NAME: {api_name: 'eq_name', required: false, label: '', display_label: ''},
    ACTION: {api_name: 'action', required: false, label: 'Action', display_label: 'Action'},
    GAS_TO_METER: {api_name: 'gas_to_meter', required: false, label: 'Gas Line To Meter', display_label: 'Gas Line To Meter'},
    IS_ELECTRICAL: {api_name: 'is_electrical', required: false, label: 'Electrical?', display_label: 'Electrical'},
    QUANTITY: {api_name: 'quantity', required: false, label: 'Quantity', display_label: 'Quantity'},
    FT: {api_name: 'ft', required: false, label: 'ft', display_label: 'ft'},
    INCH: {api_name: 'inch', required: false, label: 'in', display_label: 'in'}
  },
  SHARED_REQ: {
    LOCATION:  {api_name: 'location', required: true, label: 'Location', display_label: 'Location'},
    ACTION: {api_name: 'action', required: true, label: 'Action', display_label: 'Action'}
  },
  ELECTRICAL: {
    HOUSE_REWIRE: {api_name: 'house_rewire', required: false,  label: 'House Rewire', display_label: 'House Rewire'},
    MOTORS: {api_name: 'motors', required: false,  label: 'Motors', display_label: 'Motors'},
    BR_CIR: {api_name: 'branch_circuits', required: false,  label: 'Branch Circuits', display_label: 'Branch Circuits'},
    EV_CAR: {api_name: 'ev_car', required: false,  label: 'EV Car Charging Circuits', display_label: 'EV Car Charging Circuits', print_label: 'ac circuit'},
    SUB_PANELS: {api_name: 'sub_panels', required: false,  label: 'Sub Panels', display_label: 'Sub Panels'},
    DISC_BOX: {api_name: 'disconnected_boxes', required: false,  label: 'Disconnected Boxes', display_label: 'Disconnected Boxes'},
    VOLTS: {api_name: 'volts', required: false, label: 'Volts', display_label: 'Volts'},
    KW: {api_name: 'kw', required: false, label: 'KW', display_label: 'KW'},
    AMPS: {api_name: 'amps', required: false, label: 'AMPS', display_label: 'AMPS'},
    SERVICE_UPGRADE: {api_name: 'service_upgrade', required: false, label: 'Service Upgrade (amps)', display_label: 'Service Upgrade (amps)', print_label:'service upg'},
    SWITCHES: {api_name: 'switches', required: false, label: 'Switches', display_label: 'Switches'},
    LIGHTS:{api_name: 'lights', required: false, label: 'Lights', display_label: 'Lights'},
    OUTLETS: {api_name: 'outlets', required: false, label: 'Outlets', display_label: 'Outlets'},
  },
  PLUMBING: {
    WATER_HEATER: {api_name: 'water_heater', required: false, label: 'water heater', display_label: 'water heater'},
    WATER_SOFTNER: {api_name: 'water_softner', required: false, label: 'water softner', display_label: 'water softner'},
    REPAIR: {api_name: 'repair', required: false, label: 'repair', display_label: 'repair'},
    REPAIR_DRAIN_LINE: {api_name: 'repair_drain_line', required: false,  label: 'Repair Drain Line', display_label: 'Repair Drain Line'},
    REPAIR_SEWER: {api_name: 'repair_sewer', required: false,  label: 'Repair Sewer', display_label: 'Sewer'},
    CLEAN_OUTS: {api_name: 'clean_outs', required: false,  label: 'Clean Outs', display_label: 'Clean Outs'},
    LINERS: {api_name: 'liners', required: false,  label: 'Liners', display_label: 'Liners'},
    SPOT_REPAIR: {api_name: 'spot_repair', required: false,  label: 'Spot Repair?', display_label: 'Spot Repair', print_label: 'spot'},
    REPLACE: {api_name: 'replace', required: false, label: 'replace', display_label: 'replace'},
    REPLACE_WATER_MAIN: {api_name: 'replace_water_main', required: false, label: 'Replace Water Main', display_label: 'replace water main'},
    REPLACE_SEWER_LINE: {api_name: 'replace_sewer_line', required: false, label: 'Replace Sewer Line', display_label: 'replace sewer line'},
    SANITATION: {api_name: 'sanitation', required: false, label: 'sanitation', display_label: 'sanitation'},
    SANITATION_DISTRICT: {api_name: 'sanitation_district', required: false, label: 'sanitation district', display_label: 'sanitation district'},
    SANITATION_LOCATION: {api_name: 'san_location', required: false, label: 'Location', display_label: 'Location'},
    JURISDICTION: {api_name: 'jurisdiction', required: false, label: 'Jurisdiction', display_label: 'Jurisdiction'},
    ELECTRICAL_PERMIT: {api_name: 'electrical', required: false, label: 'Will this unit need electrical permit?', display_label: 'unit need el. permit', print_label: 'need el. permit'},
    DRAINS: {api_name: 'drains', required: false, label: 'Drains', print_label: 'drains', display_label: 'Drains'},
    DRAIN_LENGTH: {api_name: 'drain_length', required: false, label: 'Drain Length (Ft)', print_label: 'length', display_label: 'Drain Length (Ft)'},
    DRAIN_SIZE: {api_name: 'drain_size', required: false, label: 'Drain size (Ft)', print_label: 'size', display_label: 'Drain size (Ft)'},
    DRAIN_FIXTURES: {api_name: 'drain_fixtures', required: false, label: 'Drain Fixtures', print_label: 'fixtures', display_label: 'Drain Fixtures'},
    SEWER_LENGTH: {api_name: 'sewer_length', required: false, label: 'How many ft?', print_label: 'sewer line(ft)', display_label: 'Sewer Line Length (Ft)'},
    SEWER_SIZE: {api_name: 'sewer_size', required: false, label: 'Sewer size (Ft)', print_label: 'size', display_label: 'Sewer size (Ft)'},
    SPOT_LOCATION: {api_name: 'spot_location', required: false, label: 'Spot Location', print_label: 'length', display_label: 'Spot Location'},
    SIZE_SPOT_REPAIRS: {api_name: 'size_spot_repairs', required: false, label: 'Size (In)', print_label: 'location', display_label: 'Size (In)'},
    LENGTH_SPOT_REPAIRS: {api_name: 'length_spot_repairs', required: false, label: 'Length (Ft)', print_label: 'size', display_label: 'Length (Ft)'},
    CLEANOUT_LOCATIONS: {api_name: 'cleanout_locations', required: false, label: 'Cleanout Locations', print_label: 'locations', display_label: 'Cleanout Locations'},
    LINER_QUANTITY: {api_name: 'liner_quantity', required: false, label: 'Liner Quantity', print_label: 'quantity', display_label: 'Liner Quantity'},
    WATER_MAIN_DIAMETER: {api_name: 'water_main_diameter', required: false, label: 'Water Main Diameter', display_label: 'water main diameter'},
    // SEWER_LINE_FEET: {api_name: 'sewer_line_feet', required: false, label: 'sewer line feet', display_label: 'sewer line feet'},
    SEWER_LINE_DIAMETER: {api_name: 'sewer_line_diameter', required: false, label: 'Diameter (in)', display_label: 'Diameter(in)'},
    TRENCHLESS_PIPE_BURSTING: {api_name: 'trenchless_pipe_bursting', required: false, label: 'Trenchless Pipe Bursting', display_label: 'trenchless pipe bursting'},
    SEWER_LINE_PRIVATE_PROPERTY: {api_name: 'sewer_line_private_property', required: false, label: 'Sewer Line Private Property', display_label: 'sewer line private property'},
    SEWER_LINE_PUBLIC_PROPERTY: {api_name: 'sewer_line_public_property', required: false, label: 'Sewer Line Public Property', display_label: 'sewer line public property'},
    // REPIPE_WATER: {api_name: 'repipe_fresh_water', required: false, label: 'Repipe Fresh Water'},
    REPIPE: {api_name: 'repipe', required: false, label: 'repipe', display_label: 'repipe'},
    REPIPE_DRAINS: {api_name: 'repipe_drains', required: false, label: 'Repipe Drains', display_label: 'Repipe Drains'},
    REPIPE_FRESH_WATER: {api_name: 'repipe_fresh_water', required: false, label: 'Repipe Fresh Water', display_label: 'Repipe Fresh Water'},

    // REPIPE_SCOPE: {api_name: 'repipe_scope', required: false, label: 'repipe scope'},
    WH_TANKLESS: {api_name: 'wh_tankless', required: false, label: 'Tankless Water Heaters', display_label: 'Tankless Water Heaters'},
    WH_TRADITIONAL: {api_name: 'wh_traditional', required: false, label: 'Traditional Water Heaters', display_label: 'Traditional Water Heaters'},
    EXP_TANKS: {api_name: 'exp_tanks', required: false, label: 'Expansion Tanks', display_label: 'Expansion Tanks'},
    REPIPE_FIXTURES: {api_name: 'repipe_fixtures', required: false, label: 'Repipe Fixtures', display_label: 'Repipe Fixtures'},
    GALLONS: {api_name: 'gallons', required: false, label: 'Gallons', display_label: 'Gallons'},
    FUEL_TYPE: {api_name: 'fuel_type', required: false, label: 'Fuel Type', display_label: 'Fuel Type'},
    ENERGY_FACTOR: {api_name: 'energy_factor', required: false, label: 'Energy Factor', display_label: 'Energy Factor'},
    INS_R_VALUE: {api_name: 'ins_r_value', required: false, label: 'Tank Insulation R Value', display_label: 'Tank Insulation R Value', print_label: 'Tank Ins. R Value'},
    BTU_INPUT: {api_name: 'btu_input', required: false, label: 'BTU (Per Thousands)', display_label: 'BTU (Per Thousands)', print_label: 'BTU input/1000'},
    FIXTURES: {
      LAVATORIES: {api_name: 'lavatories', required: false, label: 'Lavatories', display_label: 'Lavatories'},
      TUB: {api_name: 'tub', required: false, label: 'tub', display_label: 'tub'},
      TUB_SHOWER: {api_name: 'tub_shower', required: false, label: 'Tub Shower', display_label: 'Tub Shower'},
      SHOWER: {api_name: 'shower_plumbing', required: false, label: 'Shower', display_label: 'Shower'},
      TOILET: {api_name: 'toilet', required: false, label: 'Toilet', display_label: 'Toilet'},
      LAUNDRY_SINK: {api_name: 'laundry_sink', required: false, label: 'Laundry Sink', display_label: 'Laundry Sink'},
      KITCHEN_SINK: {api_name: 'kitchen_sink', required: false, label: 'Kitchen Sink', display_label: 'Kitchen Sink'},
      WASHERS: {api_name: 'washers', required: false, label: 'Washers', display_label: 'Washers'},
      ICE_MAKERS: {api_name: 'ice_makers', required: false, label: 'Ice Makers', display_label: 'Ice Makers'},
      GARBAGE_DISPOSER: {api_name: 'garbage_disposer', required: false, label: 'Garbage Disposer', display_label: 'Garbage Disposer'},
      WATER_HEATER: {api_name: 'water_heater', required: false, label: 'Water Heater', display_label: 'Water Heater'},
      HOSE_BIBBS: {api_name: 'hose_bibbs', required: false, label: 'Hose Bibbs', display_label: 'Hose Bibbs'},
      OTHER: {api_name: 'other', required: false, label: 'Other', display_label: 'Other'},
    },
  },
  BUSINESS_LIC: {
    CITY_TO_RENEW_BL: {api_name: 'city_to_renew_bl', required: true, label: 'City to renew BL', display_label: 'City to renew BL'},
  },
  SOLAR:{
    TOTAL_KW: {api_name: 'total_kw', required: false, label: 'Total KW', display_label: 'Total KW'},
    PANELS: {api_name: 'panels', required: false, label: 'Panels', display_label: 'Panels'},
    INVERTERS: {api_name: 'inverters', required: false, label: 'Inverters', display_label: 'Inverters'},
    MODULES: {api_name: 'modules', required: false,  label: 'Modules', display_label: 'Modules'},
  },
  NOTE: {
    TEXT: {api_name: 'note', required: true, label: 'Note', display_label: 'Note'},
    TYPE: {api_name: 'type', required: false, label: 'Type', display_label: 'Type'},
    NOTIFY_EMAIL: {api_name: 'notifyEmail', required: false, label: 'Notify Users', display_label: 'Notify Users'}
  },
  FILE: {
    TEXT: {api_name: 'files', required: true, label: 'Files', display_label: 'Files'},
    UPLOAD: {api_name: 'file', required: false, label: 'File Upload', display_label: 'File Upload'},
    NAME: {api_name: 'name', required: false, label: 'File Name', display_label: 'File Name'},
    TYPE: {api_name: 'type', required: false, label: 'Type', display_label: 'Type'}
  },
  ELECTRICAL_SYSTEM: {api_name: 'el', required: false,  label: '', display_label: ''},
  TEMP_POWER_SYSTEM: {api_name: 'poles', required: false,  label: '', display_label: ''},
  WATER_HEATER_SYSTEM: {api_name: 'wh', required: false,  label: '', display_label: ''},
  MECHANICAL: {
    MAP: {api_name: 'map', required: false, label: 'map', display_label: 'map'},
    MARKS: {api_name: 'marks', required: false, label: 'marks', display_label: 'marks'},
    BACK: {api_name: 'back', required: false, label: 'Back', display_label: 'back'},
    FRONT: {api_name: 'front', required: false, label: 'Front', display_label: 'front'},
    LEFT: {api_name: 'left', required: false, label: 'Left', display_label: 'left'},
    RIGHT: {api_name: 'right', required: false, label: 'Right', display_label: 'right'},
    COOLING_SYSTEM: {api_name: 'cooling_system', required: false,  label: 'Cooling Systems', display_label: 'Cooling Systems'},
    HPU_SYSTEM: {api_name: 'hpu_system', required: false,  label: 'Heating & Package Unit Systems', display_label: 'Heating Package Unit Systems'},

    DUCTS: {api_name: 'ducting', required: false,  label: 'Ducting', display_label: 'Ducting'},
    OTHER_EQ_OPTIONS: {api_name: 'other_eq_options', required: false, label: 'Other Equipment Options', display_label: 'Other Equipment Options'},
    EL_INFO: {api_name: 'el_info', required: false, label: 'Electrical Info', display_label: 'Electrical Info'},
    PL_INFO: {api_name: 'pl_info', required: false, label: 'Plumbing Info', display_label: 'Plumbing Info'},
    INSULATION: {api_name: 'insulation', required: false, label: 'Insulation', display_label: 'Insulation'},
    SEER: {api_name: 'seer', required: false,  label: 'SEER Rating', display_label: 'SEER Rating'},
    MAKE: {api_name: 'make', required: false,  label: 'Make', display_label: 'Make'},
    MODEL:{api_name: 'model', required: false,  label: 'Model', display_label: 'Model'},
    SERIAL:{api_name: 'serial', required: false,  label: 'Serial', display_label: 'Serial'},
    TONS: {api_name: 'tons', required: false,  label: 'Tons', display_label: 'Tons'},
    NEED_ELECTRICAL: {api_name: 'need_electrical', required: false,  label: 'Need Electrical', display_label: 'Need Electrical', print_label: 'Need Ele'},
    NEED_GAS: {api_name: 'need_gas', required: false,  label: 'Need Gas', display_label: 'Need Gas'},
    BTUS: {api_name: 'btus', required: false,  label: 'BTUS (Per Thousands)', display_label: 'BTUS (Per Thousands)', print_label: 'BTUS'},
    AFUE: {api_name: 'afue', required: false,  label: 'Heater AFUE (Efficiency)', display_label: 'Heater AFUE (Efficiency)', print_label: 'Heater AFUE'},
    HSPF: {api_name: 'hspf', required: false,  label: 'Heater HSPF (Efficiency)', display_label: 'Heater HSPF (Efficiency)', print_label: 'Heater HSPF'},
    AH_QTY: {api_name: 'ah_qty', required: false,  label: 'Quantity of Air Handlers', display_label: 'Air Handlers QTY', print_label: 'Air Handlers'},
    DUCTS_QTY: {api_name: 'ducts_qty', required: false,  label: 'Ducts(QTY)', display_label: 'Ducts QTY', print_label: 'Ducts QTY'},
    SUPPLY: {api_name: 'supply', required: false,  label: 'Supply', display_label: 'Supply'},
    RETURN: {api_name: 'return', required: false,  label: 'Return', display_label: 'Return'},
    ENTIRELY_NEW_DUCT: {api_name: 'entirely_new_duct', required: false,  label: 'Entirely New Duct System', display_label: 'Entirely New Duct System', print_label: 'entirely new'},
    AC_AIR_HOOKUP: {api_name: 'ac_hookup', required: false, label: 'AC/AIR handler hookup (220V)', display_label: 'AC/AIR handler hookup (220V)', print_label: 'AC/AIR hookup(220V)'},
    AC_CIR: {api_name: 'ac_cir', required: false, label: 'AC circuit (110V)', display_label: 'AC circuit (110V)'},
    DEDICATED_CIR: {api_name: 'dedicated_cir', required: false, label: 'Dedicated Circuit FAU', display_label: 'Dedicated Circuit FAU', print_label: 'dedicated cir'},
    RADIANT_BARRIER: {api_name: 'radiant_barrier', required: false, label: 'Radiant Barrier', display_label: 'Radiant Barrier'},
    BLOWN_IN: {api_name: 'blown_in', required: false, label: 'Blown In', display_label: 'Blown In'},
    VALUATION: {api_name: 'valuation', required: true, label: 'Valuation', display_label: 'Valuation'},
    R_VALUE: {api_name: 'r_value', required: true, label: 'R Value', display_label: 'R Value'},
    WH_TYPE: {api_name: 'wh_type', required: false, label: 'Water Heater Type', display_label: 'Water Heater Type', print_label: 'wh type'},
    NEED_PLUMBING: {api_name: 'need_plumbing', required: false, label: 'Need Plumbing', display_label: 'Need Plumbing'},
    NEED_WH: {api_name: 'need_wh', required: false, label: 'Water Heater', display_label: 'Water Heater'},
    GAS_LINE: {api_name: 'gas_line', required: false, label: 'Gas Line', display_label: 'Gas Line'},
    GAS_LINE_FT: {api_name: 'gas_line_ft', required: false, label: 'Gas Line (FT)', display_label: 'Gas Line (FT)'},
    SYSTEM_COUNT: {api_name: 'systemsCount', required: false, label: 'Systems count', display_label: 'Systems count'}
  },
  COMMERCIAL: {
    BUILDING_PERMIT_NUMBER: {api_name: 'building_perm', crm_name: 'build_perm_number', required: false, label: 'Building Permit#', display_label: 'Building Permit#'},
    WITNESS_TEST: {api_name: 'witness_test', crm_name: 'witness_tests', required: false, label: 'Witness Tests', display_label: 'Witness Tests'},
    EQUIPMENT: {
      EXHAUST_FANS: EQUIPMENT.EXHAUST_FANS,
      VENT_FANS: EQUIPMENT.VENT_FANS,
      REG_GRILLS: EQUIPMENT.REG_GRILLS,
      SMOKE_DAMP: EQUIPMENT.SMOKE_DAMP,
      FIRE_DAMP: EQUIPMENT.FIRE_DAMP,
      SMOKE_FIRE_DAMP: EQUIPMENT.SMOKE_FIRE_DAMP,
      DUCT_SMOKE_DET: EQUIPMENT.DUCT_SMOKE_DET,
    }
  },
  TEMPORARY_POWER: {
    BUILDER_INFO: {api_name: 'builder_info', required: false, label: 'Builder Information'},
    BUILDER_NAME: {api_name: 'builder_name', required: false, label: 'name', display_label: 'name'},
    BUILDER_COMPANY: {api_name: 'builder_company', required: false, label: 'Company (If Applicable)', display_label: 'Company'},
    // BUILDER_EMAIL: {api_name: 'builder_email', required: false, label: 'email'},
    // BUILDER_PHONE: {api_name: 'builder_phone', required: false, label: 'phone'},
    // BUILDER_ALT_PHONE_NUMBER: {api_name: 'builder_alt_phone_number',required: false,  label: 'alt phone number'},
    // BUILDER_ADDRESS: {api_name: 'builder_address', required: false, label: 'address'},
    MISC_INFO: {api_name: 'misc_info', required: false, label: 'Misc Information', display_label: 'Misc Information'},
    JURISDICTION_CITY: {api_name: 'jurisdiction_city', required: false, label: 'Jurisdiction City', display_label: 'Jurisdiction City'},
    COUNTY: {api_name: 'county',required: false, label: 'County', display_label: 'County'},
    BUILDING_USE: {api_name: 'building_use',required: false, label: 'Building Use', display_label: 'Building Use'},
    BUILDING_PERMIT: {api_name: 'building_permit',required: false, label: 'Building Permit Or Other Permit On This Job', display_label: 'Building Permit'},
    POWER_USAGE: {api_name: 'power_usage',required: false, label: 'Power Usage', display_label: 'Power Usage'},
    BARE_POLE_QUANTITY: {api_name: 'bare_pole_quantity',required: false, label: 'Bare Pole QTY', display_label: 'Bare Pole(QTY)', print_label: 'bare pole qty'},
    TRAILER_HOOKUP_QUANTITY: {api_name: 'trailer_hookup_quantity',required: false, label: 'Trailer Hookup QTY', display_label: 'Trailer Hookup(QTY)'},
    ADDITIONAL_EQUIPMENT: {api_name: 'additional_equipment',required: false, label: 'Additional Equipment', display_label: 'Additional Equipment'},
    CIRCUITS: {api_name: 'circuits',required: false, label: 'Circuits', display_label: 'Circuits'},
    KVA: {api_name: 'kva',required: false, label: 'KVA', display_label: 'KVA'},
    TEMP_POWER_POLES: {api_name: 'temp_power_poles', required: false, label: 'Temp Power Poles', display_label: 'Temp Power Poles'},
    SUB_POLES: {api_name: 'sub_poles', required: false, label: 'Sub Poles', display_label: 'Sub Poles'},
    SUB_DISTRO_PANELS: {api_name: 'sub_distro_panels', required: false, label: 'Sub/Distro Panels', display_label: 'Sub/Distro Panels'},
    TRANSFORMERS: {api_name: 'transformers', required: false, label: 'Transformers', display_label: 'Transformers'},
    GENERATORS: {api_name: 'generators', required: false, label: 'Generators', display_label: 'Generators'},
  },
  PERMIT_NUMBER: {api_name: 'permitNumber', required: false, label: 'Permit Number', display_label: 'Permit Number'},
  PERMIT_DATE: {api_name: 'date', required: false, label: 'Permit Date', display_label: 'Permit Date'},
  CHEERS: {api_name: 'cheers', required: false, label: 'Cheers', display_label: 'Cheers'},
  CHEERS_URL: {api_name: 'projectUrl', required: false, label: 'Cheers URL', display_label: 'Cheers URL'},
  CHEERS_SITE_ID: {api_name: 'siteId', required: false, label: 'Cheers Site ID', display_label: 'Cheers Site ID'},
  CHEERS_CODE_YEAR_ID: {api_name: 'codeYearId', required: false, label: 'Cheers Code Year ID', display_label: 'Cheers Code Year ID'},
  CHEERS_CF1R_DOCUMENT_ID: {api_name: 'cf1rDocumentId', required: false, label: 'Cheers CF1R Document ID', display_label: 'Cheers CF1R Document ID'},
  CHEERS_CF1R_DOCUMENT_URL: {api_name: 'cf1rDocumentUrl', required: false, label: 'Cheers CF1R Document URL', display_label: 'Cheers CF1R Document URL'},
  CHEERS_CF1R_PROJECT_URL: {api_name: 'projectUrl', required: false, label: 'Cheers CF1R Document Name', display_label: 'Cheers CF1R Document Name'},
  REROOF: {
    HAS_HOA: {api_name: 'has_hoa', required: false, label: 'This Property Has a H.O.A.', display_label: 'Has HOA'},
    AIR_CONDITION_SPACE: {api_name: 'air_condition_space', required: false, label: 'Air Condition Space', display_label: 'Air Condition Space'},
    INCLUDE_GARAGE: {api_name: 'include_garage', required: false, label: 'Include Garage', display_label: 'Include Garage'},
    GARAGE_TYPE: {api_name: 'garage_type', required: false, label: 'Garage Type', display_label: 'Garage Type'},
    EXISTING_ROOF_TYPE: {api_name: 'existing_roof_type', required: false, label: 'Existing Roof Type',display_label: 'Existing Roof Type'},
    NEW_ROOF_TYPE: {api_name: 'new_roof_type', required: false, label: 'New Roof Type', display_label: 'New Roof Type'},
    ROOF_DECK: {api_name: 'roof_deck', required: false, label: 'Roof Deck', display_label: 'Roof Deck'},
    SUBSTRATE_TYPE: {api_name: 'substrate_type', required: false, label: 'Substrate Type', display_label: 'Substrate Type', print_label: 'subs type'},
    UNDERLAYMENT_MATERIAL: {api_name: 'underlayment_material', required: false, label: 'Underlayment Material', display_label: 'Underlayment Material', print_label: 'underlayment'},
    TILE_ROOF_REMOVED: {api_name: 'tile_roof_removed', required: false, label: 'Tile Roof Removed', display_label: 'Tile Roof Removed'},
    SLOPE: {api_name: 'slope', required: false, label: 'Slope', display_label: 'Slope'},
    MANUFACTURER: {api_name: 'manufacturer', required: false, label: 'Manufacturer', display_label: 'Manufacturer'},
    MODEL_STYLE: {api_name: 'model_style', required: false, label: 'Model Style', display_label: 'Model Style'},
    COLOR: {api_name: 'color', required: false, label: 'Color', display_label: 'Color'},
    COMP_YEAR: {api_name: 'comp_year', required: false, label: 'Comp Year', display_label: 'Comp Year'},
    ICC: {api_name: 'icc', required: false, label: 'ICC#', display_label: 'ICC#'},
    CRRC: {api_name: 'crrc', required: false, label: 'CRRC#', display_label: 'CRRC#'},
    LA_RR: {api_name: 'la_rr', required: false, label: 'LA_RR#', display_label: 'LA_RR#'},
    UL: {api_name: 'ul', required: false, label: 'UL#', display_label: 'UL#'},
    OTHER: {api_name: 'other', required: false, label: 'Other#', display_label: 'Other#'},
    BUILDINGS: {api_name: 'buildings', required: false, label: 'Buildings', display_label: 'Buildings'},
    REROOF_SCOPE: {api_name: 'reroof_scope', required: false, label: 'Scope of Work', display_label: 'Scope of work'},
    WASTE_REMOVAL: {api_name: 'waste_removal', required: false, label: 'Waste Removal', display_label: 'Waste Removal'},
    ROOFING_MATERIALS: {api_name: 'roofing_materials', required: false, label: 'Roofing Materials', display_label: 'Roofing Materials'},
    TOTAL_SQUARES: {api_name: 'total_squares', required: false, label: 'Total Squares', display_label: 'Total Squares'},
    VALUE_REROOF: {api_name: 'value_reroof', required: false, label: 'Value Reroof', display_label: 'Value'},
    NUMBER_OF_UNITS: {api_name: 'number_of_units', required: false, label: 'Number of Units', display_label: 'Number of Units'},
    NUMBER_OF_STORIES: {api_name: 'number_of_stories', required: false, label: 'Number of Stories', display_label: 'Number of Stories'},
    // ADDRESS_BUILDING: {api_name: 'address_building', required: false, label: 'address'},
    SAME_AS_JOB_ADDRESS: {api_name: 'same_as_job_address', required: false, label: 'Same as Job Address',display_label: 'Same as Job Address'},
    WASTE_TYPE: {api_name: 'waste_type', required: false, label: 'Type', display_label: 'Type'},
    WASTE_WEIGHT: {api_name: 'waste_weight', required: false, label: 'Approx. Weight', display_label: 'Approx. Weight'},
    RECYCLING: {api_name: 'recycling', required: false, label: 'Recycling', display_label: 'Recycling'},
    RECYCLING_COMPANY: {api_name: 'recycling_company', required: false, label: 'Recycling Company (If Applicable)', display_label: 'Company'},
  },
  ATTACHMENT: {
    TEXT: {api_name: 'attachments', required: false, label: 'Attachments', display_label: 'Attachments'},
    ID: {api_name: 'id', required: false, label: 'ID', display_label: 'ID'},
    FILE_ID: {api_name: 'fileId', required: false, label: 'File ID', display_label: 'File ID'},
    FILE_UPLOAD_ID: {api_name: 'fileUploadId', required: false, label: 'File Upload ID', display_label: 'File Upload ID'},
    FILE_NAME: {api_name: 'fileName', required: false, label: 'File Name', display_label: 'File Name'},
    TYPE: {api_name: 'type', required: false, label: 'Type', display_label: 'Type'},
    CREATED_AT: {api_name: 'createdAt', required: false, label: 'Created At', display_label: 'Created At'},
    UPLOAD: {api_name: 'uploadedBy', required: false, label: 'Uploaded By', display_label: 'Uploaded By'},
    UPLOADED_BY: {
      NAME: {api_name: 'name', required: false, label: 'Name', display_label: 'Name'},
      ADDED_FROM_USER: {api_name: 'addedFrom', required: false, label: 'Added From', display_label: 'Added From'},
    },
    ADDED_FROM: {
      CONTRACTOR: {api_name: 'contractor', required: false, label: 'Contractor', display_label: 'Contractor'},
      IPERMIT: {api_name: 'ipermit', required: false, label: 'iPermit', display_label: 'iPermit'},
    },
    ATTACHMENT_TYPE: {
      HERS: {api_name: 'hers', required: false, label: 'HERS', display_label: 'HERS'},
      PERMIT: {api_name: 'permit', required: false, label: 'PERMIT', display_label: 'PERMIT'},
    },
  }
};

export default JOB_FIELDS;
