import React from 'react';
import {companyPropTypes, companyDefaultProps} from './panelProps';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import * as allConstants from '../../../constants/allConstants';
import Grid from '@mui/material/Grid';
import {Box, Card, CardContent} from '@mui/material';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import {useFormContext} from 'react-hook-form';
import {NotAllowedToEditSection} from './notAllowedToEditSection';

export const CompanySettings = (props)=> {
  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;

  const formMethods = useFormContext();

  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };

  const companyData = getCompanyData();

  const getDefaultValue = (field, parent = null) => {
    if (parent) {
      return companyData?.[parent]?.[field];
    }
    return companyData?.[field];
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%'
  };

  return (
    <>

      <Grid container maxWidth={'560px'} spacing={3}>
        <NotAllowedToEditSection show={props.tabProps.notAllowedToEdit}/>
        <Grid item sm={12}>
          <Card className={classes.card_block}>
            <CardContent className={classes.half_width}>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.NAME}
                defaultValue={getDefaultValue(COMPANY_FIELDS.NAME.api_name)}
              />
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.EMAIL}
                defaultValue={getDefaultValue(COMPANY_FIELDS.EMAIL.api_name)}
              />
              {props.tabProps.isIpermitInvite && <CustomFormBlock
                {...props.inputDefaultProps}
                field={COMPANY_FIELDS.STATE_LICENSE_NUMBER}
                nested={true}
                parent={COMPANY_FIELDS.STATE_LICENSE.api_name}
                fullWidth={true}
                defaultValue={getDefaultValue(COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name, COMPANY_FIELDS.STATE_LICENSE.api_name)}
              />
              }
              <Box className={classes.two_col_container} sx={{
                '& .MuiSelect-multiple': {
                  minWidth: 'unset !important'
                }
              }}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.LOCATION}
                  inputType={inputTypes.multiSelect}
                  options={Utils.getOptions(props?.tabProps?.options?.markets)}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.LOCATION.api_name)}
                />
                <CustomFormBlock key={JSON.stringify(getDefaultValue(COMPANY_FIELDS.ALLOWED_TESTS.api_name))}
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.ALLOWED_TESTS}
                  inputType={inputTypes.multiSelect}
                  options={Utils.getOptions(allConstants.TEST_TYPES)}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.ALLOWED_TESTS.api_name)}
                />
              </Box>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.ALLOWED_PERMITS}
                inputType={inputTypes.multiSelect}
                options={Utils.getOptions(allConstants.JOB_TYPES)}
                width={'100%'}
                defaultValue={getDefaultValue(COMPANY_FIELDS.ALLOWED_PERMITS.api_name)}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

CompanySettings.propTypes = companyPropTypes;
CompanySettings.defaultProps = companyDefaultProps;
